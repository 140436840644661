import JwtDecode from 'jwt-decode';

const ID_TOKEN_NAME = 'idToken';

export const isUserLogIn = () => {
  if (!localStorage.getItem(ID_TOKEN_NAME)) {
    return true;
  }
  try {
    const decodedToken = JwtDecode(localStorage.getItem(ID_TOKEN_NAME) as string) as any;
    return Date.now() <= decodedToken.exp * 1000;
  } catch (error) {
    // token is invalid
    return true;
  }
};

export const isEmailVerified = () => {
  try {
    const decodedToken = JwtDecode(localStorage.getItem(ID_TOKEN_NAME) as string) as any;
    return decodedToken.email_verified;
  } catch (error) {
    // token is invalid
    return false;
  }
};

export const getUserName = () => {
  const decodedToken = JwtDecode(localStorage.getItem(ID_TOKEN_NAME) as string) as any;
  return decodedToken.userName;
};
