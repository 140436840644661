import React, { FC } from 'react';

type TeamLogoProps = {
  logoUrl: string | undefined;
  style?: any;
};

export const TeamLogo: FC<TeamLogoProps> = ({ logoUrl, style }) => {
  return <> {logoUrl && <img src={logoUrl} style={{ width: '80px', marginRight: '8px', ...style }} />}</>;
};
