import React, { FC } from 'react';
import styled from 'styled-components';
import { BettingPlayerStatus, Player } from '../../../beTypes';
import PlayerActionButton from './PlayerActionButton/PlayerActionButton';
import { Tag } from 'antd';
import { queryCache, useMutation } from 'react-query';
import FirebaseService from 'global/Firebase/FirebaseService';
import { PRIVATE_LEAGUE_DETAILS, PRIVATE_LEAGUE, STANDINGS } from 'helpers/RequestKeys';
import { Card } from '../../Card/Card';
import { CenteredSpinner } from '../../CenteredSpinner/CenteredSpinner';

const TagStyle = styled(Tag)`
  // align with spinner
  margin-bottom: 4px;
`;

const NameAndTagBox = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
`;

const GreyCard = styled(Card)`
  width: 100%;
  padding: 16px 24px 24px 24px;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`;

const TagsMap = {
  waiting: <TagStyle color="processing">waiting</TagStyle>,
  accepted: <TagStyle color="success">accepted</TagStyle>,
  banned: <TagStyle color="error">banned</TagStyle>,
};

// this is used to change statuses by button click
const statusChangeMap: Record<BettingPlayerStatus, BettingPlayerStatus> = {
  waiting: 'accepted',
  accepted: 'banned',
  banned: 'accepted',
};

type ChangePlayerStatusInput = {
  pin: string;
  status: BettingPlayerStatus;
  userName: string;
};

type Props = {
  player: Player;
  pin: string;
};

const PlayerBox: FC<Props> = ({ player, pin }) => {
  const [changePlayerStatus, { isLoading }] = useMutation<any, any, ChangePlayerStatusInput>(
    ({ pin, status, userName }) => FirebaseService.changePlayerStatus(pin, status, userName),
    {
      onSuccess: async (_, variables) => {
        const { privateLeague }: any = await queryCache.getQueryData([PRIVATE_LEAGUE_DETAILS, pin]);
        queryCache.invalidateQueries([PRIVATE_LEAGUE, pin]);
        queryCache.invalidateQueries([STANDINGS]);
        const updatedPlayer = {
          ...privateLeague.players.find((player: any) => player.userName === variables.userName),
          status: variables.status,
        };
        queryCache.setQueryData([PRIVATE_LEAGUE_DETAILS, pin], () => ({
          privateLeague: {
            ...privateLeague,
            players: [
              ...privateLeague.players.filter((player: any) => player.userName !== variables.userName),
              updatedPlayer,
            ],
          },
        }));
      },
    }
  );
  return (
    <GreyCard>
      <NameAndTagBox>
        <h6>
          <b>{player.userName}</b>
        </h6>
        <StatusContainer>
          {TagsMap[player.status]}
          {isLoading && <CenteredSpinner />}
        </StatusContainer>
      </NameAndTagBox>
      <PlayerActionButton
        status={player.status}
        onClick={() =>
          changePlayerStatus({
            status: statusChangeMap[player.status],
            userName: player.userName,
            pin,
          })
        }
      />
    </GreyCard>
  );
};

export default PlayerBox;
