import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const CenteredSpinner = (props: any) => {
  return (
    <StyledDiv>
      <Spin {...props} />
    </StyledDiv>
  );
};
