import React, { FC, useState } from 'react';
import { Badge, message, notification, Table, Popconfirm } from 'antd';
import styled from 'styled-components';
import Dollar from 'assets/Dollar.svg';
import { TopNav } from '../../components/TopNav/TopNav';
import { RowSelectionType } from 'antd/lib/table/interface';
import { LoginButton } from '../../components/Button/LoginButton';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { APP_ROUTES } from '../../global/AppRouter/routes';
import { queryCache, useMutation, useQuery } from 'react-query';
import { PRIVATE_LEAGUE, PRIVATE_LEAGUES } from '../../helpers/RequestKeys';
import FirebaseService from '../../global/Firebase/FirebaseService';
import { CenteredSpinner } from '../../components/CenteredSpinner/CenteredSpinner';
import { GameLeague, Team } from '../../beTypes';
import SpinnerWithOverlay from '../../components/SpinnerWithOverlay/SpinnerWithOverlay';
import { TeamLogo } from '../../components/TeamLogo/TeamLogo';

const CreditStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.typography.subtitle1};
  font-weight: bold;
`;

const ImgStyled = styled.img`
  margin-left: 8px;
`;

const CreditsBadge = styled(Badge)`
  margin-left: 4px;
  float: center;
  .ant-badge-count {
    background: ${props => props.theme.colors.main.secondary};
  }
`;

const CreditsBadgeRed = styled(Badge)`
  margin-left: 4px;
  float: center;
  .ant-badge-count {
    background: ${props => props.theme.colors.functional.error};
  }
`;

const CreditCellContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.div`
  ${props => props.theme.typography.subtitle1};
  font-weight: bold;
`;

const ChooseLeagueText = styled.div`
  ${props => props.theme.typography.overline};
  margin: 30px 0 14px 16px;
`;

const CreditsAndAcceptBox = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.colors.utils.text.light};
  background-color: ${props => props.theme.colors.main.primary};
  padding: 30px 30px 30px 24px;
  bottom: 0;

  position: fixed;
  height: auto;
  width: 100%;
`;

const StyleTable = styled(Table)``;

const PlayButton = styled(LoginButton)`
  && {
    background-color: ${props => props.theme.colors.main.secondary};
    color: ${props => props.theme.colors.utils.text.light};
    border-radius: 2px;
  }
`;
const PlayButtonDisabled = styled(LoginButton)`
  && {
    background-color: ${props => props.theme.colors.functional.disabled};
    color: ${props => props.theme.colors.utils.text.light};
    border-radius: 2px;
    .ant-btn[disabled] {
      background-color: ${props => props.theme.colors.functional.disabled} !important;
      color: ${props => props.theme.colors.utils.text.light} !important;
    }
  }
`;

const columns = [
  {
    title: <StyledText>Team</StyledText>,
    dataIndex: 'name',
    width: '50%',
    editable: true,
    render: (name: string, record: any) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <TeamLogo logoUrl={record.logo} />
          {name}
        </div>
      );
    },
  },
  {
    title: (
      <CreditStyled>
        Credits
        <ImgStyled src={Dollar} alt="Dollar sign" />
      </CreditStyled>
    ),
    dataIndex: `credits`,
    width: '30%',
    editable: true,
    render: (credits: number) => (
      <CreditCellContainer>
        <CreditsBadge count={credits} showZero />
      </CreditCellContainer>
    ),
  },
];

type BetLeagueDataType = {
  gameLeagueId: number;
  teamsBets: { id: number; credits: number }[];
  pin: string;
};

const StyleLimitExceeded = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

const TableWrapper = styled.div`
  padding-bottom: 100px;
`;

const NotificationError = () => {
  notification.error({
    message: <StyleLimitExceeded>Limit exceeded</StyleLimitExceeded>,
    description: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Your credit limit was exceeded.
        <br /> Try to better balance your budget.
      </div>
    ),
    duration: 1500,
    style: {
      width: 'auto',
      height: 'auto',
      margin: '16px 0 0 16px',
      display: 'flex',
      alignItems: 'center',
      padding: '16px 28px 16px 24px',
    },
  });
};

type AreCreditsExceededPropTypes = {
  maxCredits: number;
  privateLeagueName: string;
  userCredits: number[];
  teamsBets: any[];
  betLeague: (any: any) => any;
};

const AreCreditsExceeded: FC<AreCreditsExceededPropTypes> = ({
  maxCredits,
  privateLeagueName,
  userCredits,
  teamsBets,
  betLeague,
}) => {
  const { leagueId: gameLeagueId, pin } = useParams();
  const history = useHistory();
  if (_.sum(userCredits) <= maxCredits) {
    return (
      <CreditsAndAcceptBox>
        <CreditStyled>
          Credits
          <ImgStyled src={Dollar} alt="Dollar sign" />
          <CreditsBadge count={`${_.sum(userCredits)} / ${maxCredits}`} />
        </CreditStyled>
        <Popconfirm
          placement="topRight"
          title="Are you happy with your selections? You can not go back if you press play."
          onConfirm={async () => {
            if (teamsBets.length !== 4) {
              message.error('You need to pick exactly 4 teams.');
              return;
            }

            await betLeague({
              pin,
              teamsBets,
              gameLeagueId,
            });
            history.replace(APP_ROUTES.LEAGUE(privateLeagueName, pin, 'GAME'));
          }}
          okText="Yes"
          cancelText="No"
        >
          <PlayButton TextInButton={`Play`} />
        </Popconfirm>
      </CreditsAndAcceptBox>
    );
  } else {
    return (
      <CreditsAndAcceptBox>
        <CreditStyled>
          Credits
          <ImgStyled src={Dollar} alt="Dollar sign" />
          <CreditsBadgeRed count={`${_.sum(userCredits)} / ${maxCredits}`} />
        </CreditStyled>
        <PlayButtonDisabled
          TextInButton="Play"
          onClick={() => {
            NotificationError();
          }}
        />
      </CreditsAndAcceptBox>
    );
  }
};

export const LeaguePinBet: FC = () => {
  const { privateLeagueName, leagueId, leagueName, pin } = useParams();

  const { data, isLoading } = useQuery<any, any, any>([PRIVATE_LEAGUE, pin], () =>
    FirebaseService.getPrivateLeagueDetails(pin)
  );

  const [betLeague, { isLoading: isPlayLoading }] = useMutation(
    (betLeagueData: BetLeagueDataType) =>
      FirebaseService.betLeague(betLeagueData.gameLeagueId, betLeagueData.teamsBets, betLeagueData.pin),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(PRIVATE_LEAGUES);
        queryCache.invalidateQueries([PRIVATE_LEAGUE, pin]);
      },
    }
  );

  const [selectionType] = useState<RowSelectionType>('checkbox');
  const [userCredits, setUserCredits] = useState([]);
  const [teamsBets, setTeamsBets] = useState([]);

  const gameLeague =
    data &&
    !isLoading &&
    data.privateLeague.gameLeagues.find((gameLeague: GameLeague) => gameLeague.id === Number(leagueId));

  const tableData =
    gameLeague &&
    gameLeague.teams.map((team: Team) => ({
      credits: team.credits,
      name: team.name,
      logo: team.logo,
      key: team.id,
    }));
  const rowSelection = {
    onChange: (_selectedRowKeys: any, selectedRows: any) => {
      setUserCredits(selectedRows.map((value: any) => value.credits));
      setTeamsBets(
        selectedRows.map((row: any) => ({
          id: row.key,
          credits: row.credits,
        }))
      );
    },
  };

  return (
    <>
      <TopNav isWithBackArrow>{leagueName}</TopNav>
      {isPlayLoading && <SpinnerWithOverlay />}
      <ChooseLeagueText>CHOOSE 4 TEAMS</ChooseLeagueText>
      {!data && isLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          <TableWrapper>
            <StyleTable
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={columns}
              dataSource={tableData}
              pagination={false}
            />
          </TableWrapper>

          <AreCreditsExceeded
            maxCredits={gameLeague && gameLeague.maxCredits}
            betLeague={betLeague}
            privateLeagueName={privateLeagueName}
            teamsBets={teamsBets}
            userCredits={userCredits}
          />
        </>
      )}
    </>
  );
};
