import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { APP_ROUTES } from 'global/AppRouter/routes';
import { getUrlsParams } from 'helpers/getUrlsParams';

export type ActionType = 'verify' | 'verificationEmailSent';

export const Action: FC = () => {
  const { mode, oobCode, continueUrl, userName } = getUrlsParams(['oobCode', 'continueUrl', 'mode', 'userName']);
  const history = useHistory();

  if (mode === 'resetPassword') {
    history.replace(`${APP_ROUTES.NEW_PASSWORD}?oobCode=${oobCode}&continueUrl=${continueUrl}`);
    return null;
  }

  if (mode === 'verifyEmail') {
    history.replace(`${APP_ROUTES.LOGIN}?actionCode=${oobCode}&action=verify&userName=${userName}`);
    return null;
  }

  history.replace(APP_ROUTES.LOGIN);

  return null;
};
