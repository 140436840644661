import React, { FC } from 'react';
import styled from 'styled-components';
import leagues from 'assets/leagues.svg';
import createLeague from 'assets/createLeague.svg';
import { Card } from '../../components/Card/Card';
import { Link, NavLink } from 'react-router-dom';
import { APP_ROUTES } from '../../global/AppRouter/routes';
import { AdsComponent } from '../../components/AdsComponent/AdsComponent';
import { TopNavWithLogo } from '../../components/TopNavWithLogo/TopNavWithLogo';
import { DrawerLeagueFromLink } from '../../components/Drawer/DrawerLeagueFromLink';
const GreyCard = styled(Card)`
  && {
    padding: 40px;
    display: flex;
    font-weight: bold;
    width: 100%;
    flex-direction: row;
  }
`;

const ImgStyle = styled.img`
  margin-left: 4px;
`;

const LinkStyle = styled(Link)`
  margin-right: 2px;
  ${props => props.theme.typography.subtitle1};
  color: ${props => props.theme.colors.utils.text.dark};
  text-decoration: none;
  font-weight: bold;
  width: 100%;
  display: flex;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.utils.text.dark};
  width: 100%;
  display: flex;
`;

const AdsComponentStyle = styled(AdsComponent)`
  margin-bottom: 30px;
`;

const WholeComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 16px 0px 16px;
`;

export const DashboardMain: FC = () => {
  return (
    <>
      <TopNavWithLogo />
      <WholeComponent>
        <AdsComponentStyle />
        <StyledNavLink to={APP_ROUTES.LEAGUES}>
          <GreyCard>
            Leagues
            <ImgStyle src={leagues} alt="Leagues" />
          </GreyCard>
        </StyledNavLink>
        <DrawerLeagueFromLink />
        <LinkStyle to={APP_ROUTES.CREATE_LEAGUE}>
          <GreyCard>
            Create league
            <ImgStyle src={createLeague} alt="Create League" />
          </GreyCard>
        </LinkStyle>
      </WholeComponent>
    </>
  );
};
