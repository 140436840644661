import React, { FC } from 'react';
import styled from 'styled-components';
import { Tabs, message } from 'antd';
import { TopNav } from 'components/TopNav/TopNav';
import { TableMenu } from 'components/LeagueName/TableMenu';
import { PlayerMenu } from 'components/LeagueName/PlayerMenu';
import { GameMenuPreview } from '../../components/LeagueName/GameMenuPreview';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { PRIVATE_LEAGUE } from '../../helpers/RequestKeys';
import FirebaseService from '../../global/Firebase/FirebaseService';
import SpinnerWithOverlay from '../../components/SpinnerWithOverlay/SpinnerWithOverlay';
import { getUserName } from '../../helpers/token';
const { TabPane } = Tabs;

const CenteredCol = styled(Tabs)`
  .ant-tabs {
    width: 100%;
  }
  .ant-tabs-nav {
    position: fixed;
    z-index: 1000;
    height: 40px;
    width: 100%;
    z-index: 999;
    text-align: center;
  }

  && {
    .ant-tabs-nav-wrap {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    }
  
    .ant-tabs-nav-list {
      width: 100%;
      text-align: center;
    }
    && .ant-tabs-tab {
      margin: 0px;
      border: 0px;
      width: 100%;
      background-color: ${props => props.theme.colors.main.primary};
      color: ${props => props.theme.colors.utils.background.light};
      ${props => props.theme.typography.subtitle2};
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-tabs-ink-bar {
      visibility: visible;
      background-color: ${props => props.theme.colors.utils.background.light};
    }
    .ant-tabs-content-holder {
      width: 100%;
    }
    .ant-tabs-nav {
      margin: 0px;
    }
    .ant-tabs-nav-list {
      background-color: ${props => props.theme.colors.main.primary};
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color:${props => props.theme.colors.utils.text.light}
  }
`;

export const LeagueMenu: FC = () => {
  const { pin, name, tabName } = useParams();

  const { data, isLoading } = useQuery<any, any, any>([PRIVATE_LEAGUE, pin], () =>
    FirebaseService.getPrivateLeagueDetails(pin)
      .then()
      .catch(error => {
        message.error(error.message);
      })
  );

  return (
    <>
      {isLoading && <SpinnerWithOverlay />}
      <TopNav isWithBackArrow>{name}</TopNav>
      <CenteredCol defaultActiveKey={tabName || 'TABLE'}>
        <TabPane tab="TABLE" key="TABLE">
          <TableMenu />
        </TabPane>
        {data && !data.privateLeague.isUserPlaying ? (
          <TabPane tab="GAME" key="GAME">
            <GameMenuPreview />
          </TabPane>
        ) : null}
        {data && data.privateLeague.owner === getUserName() && (
          <TabPane tab="PLAYERS" key="PLAYERS">
            <PlayerMenu pin={pin} />
          </TabPane>
        )}
      </CenteredCol>
    </>
  );
};
