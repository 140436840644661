import React, { FC } from 'react';
import styled from 'styled-components';
import { BettingPlayerStatus } from '../../../../beTypes';
import { LoginButton } from '../../../Button/LoginButton';

const BanButton = styled(LoginButton)`
  && {
    background-color: ${props => props.theme.colors.functional.danger};
  }
`;

type Props = {
  status: BettingPlayerStatus;
  onClick: () => void;
};

const PlayerActionButton: FC<Props> = ({ status, onClick }) => {
  switch (status) {
    case 'waiting':
      return <LoginButton TextInButton="Accept" onClick={onClick} />;
    case 'accepted':
      return <BanButton TextInButton="Ban" onClick={onClick} />;
    case 'banned':
      return <LoginButton TextInButton="Unban" onClick={onClick} />;
  }
};

export default PlayerActionButton;
