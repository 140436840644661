import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { AD } from '../../helpers/RequestKeys';
import FirebaseService from '../../global/Firebase/FirebaseService';
import firebase from 'firebase';

export type CardType = {
  className?: string;
};

const AdsBox = styled.div`
  background-color: #bfbfbf;
  height: 50px;
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.utils.text.light};
`;

export const AdsComponent: FC<CardType> = ({ className }) => {
  const { data, isLoading } = useQuery(AD, FirebaseService.getAd);
  const getAd = data && data.redirectionUrl;
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const images = firebase.storage().ref();
    const image = images.child('AdImage');
    image.getDownloadURL().then(url => {
      setImageUrl(url);
    });
  });

  if (isLoading) return null;

  return (
    <>
      {imageUrl === '' ? null : (
        <a rel="noreferrer" target="_blank" href={getAd}>
          <AdsBox className={className}>
            <img src={imageUrl} alt="Ad image" />
          </AdsBox>
        </a>
      )}
    </>
  );
};
