import { TabName } from 'beTypes';

export const APP_ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  CREATE_ACCOUNT: '/create/account',
  NEW_PASSWORD: '/new-password',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  CREATE_LEAGUE: '/create/league',
  LEAGUE: (name: string, leaguePin: string, tabName?: TabName | ':tabName') =>
    tabName ? `/league/${name}/${leaguePin}/${tabName}` : `/league/${name}/${leaguePin}`,
  LEAGUE_PREVIEW_GAME: (name: string, leaguePin: string, userName: string) =>
    `/league/preview/${name}/${leaguePin}/${userName}`,
  DASHBOARD_MENU: '/dashboard/menu',
  LEAGUES_DATA: '/dashboard/leagues-data',
  DASHBOARD_LEAGUES_ID: (name: string, id: string) => `/dashboard/leagues-data/${name}/${id}`,
  HOME: '/home',
  LEAGUES: '/leagues',
  DASHBOARD_LOGIN: '/dashboard',
  LEAGUES_CHECK: '/leagues/check',
  ACTION: '/action',
  AD_MANAGER: '/dashboard/ad-manager',
  LEAGUE_MENU_PREVIEW: '/league/menu/preview',
  LEAGUE_PIN_PLAY: (privateLeagueName: string, pin: string, leagueName: string, leagueId: number | string) =>
    `/league/${privateLeagueName}/${pin}/play/${leagueName}/${leagueId}`,
  JOIN_LEAGUE: (pin: string) => `/home/join-league/${pin}`,
};
