import React, { FC } from 'react';
import { Icon } from 'components/Icon/Icon';
import styled, { css } from 'styled-components';
import LogoutSvg from 'assets/LogoutsSvg.svg';
import { NavLink, useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'global/AppRouter/routes';
import homeIcon from 'assets/homeIcon.svg';
import HelpCenterSvg from 'assets/help_center.svg';
import { DrawerMenu } from './DrawerMenu';
import firebase from 'firebase';
import { queryCache } from 'react-query';
import { HOW_TO_PLAY_URL } from '../../helpers/consts';

const TextInHamburger = styled.div`
  margin-left: 16px;
  color: ${props => props.theme.colors.utils.text.dark};
  && {
    text-decoration: none;
  }
  ${props => props.theme.typography.subtitle2};
`;

const HamburgerRowStyle = css`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.main.primary};
  height: 50px;
  width: 100%;
  border-radius: 4px;
  text-decoration: none;
  :hover {
    background-color: rgba(85, 133, 255, 0.2);
  }
`;

const LinkRouterStyle = styled(NavLink)`
  ${HamburgerRowStyle}
`;

const LogoutButton = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.main.primary};
  height: 50px;
  width: 100%;
  border-radius: 4px;
  text-decoration: none;
  :hover {
    background-color: rgba(85, 133, 255, 0.2);
  }
`;

const StyledALink = styled.a`
  ${HamburgerRowStyle}
`;

export const HamburgerMenu: FC = ({}) => {
  const history = useHistory();

  return (
    <>
      <DrawerMenu>
        <LinkRouterStyle to={APP_ROUTES.HOME}>
          <Icon svgLink={homeIcon} />
          <TextInHamburger>Home</TextInHamburger>
        </LinkRouterStyle>

        <StyledALink href={HOW_TO_PLAY_URL} rel="noreferrer" target="_blank">
          <Icon svgLink={HelpCenterSvg} />
          <TextInHamburger>How to play?</TextInHamburger>
        </StyledALink>

        <LogoutButton
          onClick={() => {
            firebase
              .auth()
              .signOut()
              .then(function() {
                queryCache.clear();
                history.push(APP_ROUTES.LOGIN);
              })
              .catch(function(error) {
                console.log(error);
              });
          }}
        >
          <Icon svgLink={LogoutSvg} />
          <TextInHamburger>Logout</TextInHamburger>
        </LogoutButton>
      </DrawerMenu>
    </>
  );
};
