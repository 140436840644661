import React, { FC } from 'react';
import { TopNav } from 'components/TopNav/TopNav';
import styled from 'styled-components';
import { Card } from 'components/Card/Card';
import { LoginButton } from 'components/Button/LoginButton';
import { APP_ROUTES } from 'global/AppRouter/routes';
import { Link } from 'react-router-dom';
import { Empty, notification, Popconfirm, Tag } from 'antd';
import { queryCache, useMutation, useQuery } from 'react-query';
import { PRIVATE_LEAGUES } from 'helpers/RequestKeys';
import FirebaseService from 'global/Firebase/FirebaseService';
import { AdsComponent } from 'components/AdsComponent/AdsComponent';
import SpinnerWithOverlay from 'components/SpinnerWithOverlay/SpinnerWithOverlay';
import { CopyLinkParagraph } from '../../components/CopyLinkParagraph/CopyLinkParagraph';
import _ from 'lodash';
import { Player } from '../../beTypes';
import { isUserPlayerWaiting } from '../../helpers/player';

const MainComponent = styled.div`
  padding: 38px 16px 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const GreyCard = styled(Card)`
  padding: 16px 20px 20px 24px;
  margin-bottom: 18px;
  text-align: left;
  flex-direction: column;
  width: 100%;
`;

const PlayersBox = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyleCancelButton = styled(LoginButton)`
  && {
    background-color: ${props => props.theme.colors.functional.danger};
    color: ${props => props.theme.colors.utils.text.light};
    ${props => props.theme.typography.body1}
    border-radius: 2px;
  }
`;

const ButtonRight = styled(LoginButton)`
  background-color: ${props => props.theme.colors.main.primary};
  color: ${props => props.theme.colors.utils.text.light};
  border-radius: 2px;
`;

const PinBox = styled.div`
  margin-bottom: 20px;
`;

const StyleLink = styled(Link)`
  text-decoration: none;
`;

const AdsComponentStyle = styled(AdsComponent)`
  margin-bottom: 30px;
`;

export const LeaguesCheck: FC = () => {
  const { data, isLoading, error } = useQuery(PRIVATE_LEAGUES, FirebaseService.getPrivateLeagues);
  const [removePrivateLeague] = useMutation((pin: string) => FirebaseService.removePrivateLeague(pin), {
    onSuccess: ({ removed }: { removed: string }) => {
      queryCache.setQueryData(PRIVATE_LEAGUES, {
        ...data,
        privateLeagues: data.privateLeagues.filter((league: any) => league.pin !== removed),
      });
    },
    onError: () => {
      notification.error({
        message: 'Failed to delete league',
      });
    },
  });

  if (error) return <span>Error...</span>;

  return (
    <div>
      <TopNav isWithBackArrow={true}>Leagues</TopNav>
      {isLoading && <SpinnerWithOverlay background={false} />}
      <MainComponent>
        <AdsComponentStyle />
        {data && data.privateLeagues.length > 0
          ? _.orderBy(data.privateLeagues, ['name'], ['asc', 'desc']).map((league: any) => (
              <GreyCard key={league.id}>
                <h6>
                  <b>{league.name}</b>
                </h6>
                <PlayersBox>
                  Players: {league.players.filter((player: Player) => player.status === 'accepted').length}
                </PlayersBox>
                <PinBox>
                  <CopyLinkParagraph pin={league.pin}>
                    {' '}
                    Pin:<b> {league.pin}</b>{' '}
                  </CopyLinkParagraph>
                </PinBox>
                <ButtonBox>
                  {league.isUserAnOwner && (
                    <Popconfirm
                      title="Are you sure delete this league?"
                      onConfirm={() => {
                        removePrivateLeague(league.pin);
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <StyleCancelButton TextInButton="Close" />
                    </Popconfirm>
                  )}
                  {isUserPlayerWaiting(league) && !league.isUserAnOwner ? (
                    <Tag color="processing">waiting for owner approval</Tag>
                  ) : (
                    <StyleLink to={APP_ROUTES.LEAGUE(league.name, league.pin)}>
                      <ButtonRight TextInButton="Manage" />
                    </StyleLink>
                  )}
                </ButtonBox>
              </GreyCard>
            ))
          : !isLoading && <Empty />}
      </MainComponent>
    </div>
  );
};
