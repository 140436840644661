import React, { FC } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import LeftArrowIcon from 'assets/left_arrow.svg';
import { HamburgerMenu } from '../HamburgerMenu/HamburgerMenu';
import { isUserLogIn, isEmailVerified } from '../../helpers/token';

type TopNavType = {
  isWithBackArrow?: boolean;
};

const NavWrapper = styled.div`
  width: 100%;
  padding: 16px 20px;
  min-height: 56px;
  background-color: ${props => props.theme.colors.main.primary};
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackWrapper = styled.span`
  height: 100%;
`;

const NameOfTopNav = styled.div`
  ${props => props.theme.typography.subtitle1};
  color: ${props => props.theme.colors.utils.text.light};
  height: 100%;
  padding: 0 12px;
`;

const NameOfTopNavWithBackArrow = styled.div`
  ${props => props.theme.typography.subtitle1};
  color: ${props => props.theme.colors.utils.text.light};
  height: 100%;
  padding: 0 12px;
`;

const NavWrapperWithoutArrow = styled.div`
  width: 100%;
  padding: 16px 20px;
  min-height: 56px;
  background-color: ${props => props.theme.colors.main.primary};
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoNav = styled.div`
  margin-left: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopNav: FC<TopNavType> = ({ isWithBackArrow, children }) => {
  const history = useHistory();

  const IsWithHamburgerMenu = () => {
    if (isUserLogIn()) {
      return <HamburgerMenu />;
    } else {
      return <div />;
    }
  };

  return (
    <>
      {isWithBackArrow ? (
        <NavWrapper>
          <BackWrapper onClick={() => history.goBack()}>
            <img src={LeftArrowIcon} alt="left arrow icon" />
          </BackWrapper>
          <NameOfTopNavWithBackArrow>{children}</NameOfTopNavWithBackArrow>
          {isEmailVerified() ? <IsWithHamburgerMenu /> : <div />}
        </NavWrapper>
      ) : (
        <NavWrapperWithoutArrow>
          <LogoNav>
            <NameOfTopNav>{children}</NameOfTopNav>
          </LogoNav>
          {isEmailVerified() ? <IsWithHamburgerMenu /> : null}
        </NavWrapperWithoutArrow>
      )}
    </>
  );
};
