import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { TopNav } from '../../components/TopNav/TopNav';
import { Input, message, Upload } from 'antd';
import { LinkOutlined, LoadingOutlined } from '@ant-design/icons/lib';
import { AdsComponent } from '../../components/AdsComponent/AdsComponent';
import { LoginButton } from '../../components/Button/LoginButton';
import plus from 'assets/plus.svg';
import firebase from 'firebase';
import { queryCache, useMutation } from 'react-query';
import { AD } from '../../helpers/RequestKeys';
import FirebaseService from '../../global/Firebase/FirebaseService';

const AdMainBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 34px 16px;
  flex-direction: column;
`;

const InputStyle = styled(Input)`
  && {
    margin-bottom: 34px;
    padding-left: 34px;
    width: 100%;
  }
`;

const StyleLinkOutlined = styled(LinkOutlined)`
  color: ${props => props.theme.colors.main.secondary};
  position: absolute;
  top: 13px;
  left: 12px;
`;

const InputAndLink = styled.div`
  position: relative;
  width: 100%;
`;

const ButtonStyle = styled(LoginButton)`
  margin-top: 164px;
  width: 100%;
`;

const AdsComponentStyle = styled(AdsComponent)`
  height: 50px;
  width: 320px;
`;

const AdPreviewBox = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 12px;
`;

const MainComponent = styled.div`
  width: 100%;
  height: 100%;
`;

const UploadDiv = styled.div`
  height: 50px;
  width: 320px;
  border: 1px dashed ${props => props.theme.colors.utils.border.mid};
  margin-bottom: 40px;
`;

const UploadAdsStyle = styled(Upload)`
  &&& {
    .ant-upload,
    ant-upload-select,
    ant-upload-select-picture-card {
      height: 50px;
      width: 320px;
      padding: 0;
      border: none;
    }
    .ant-upload-list-picture-card-container {
      height: 50px;
      width: 320px;
      padding: 0;
      border: none;
    }
    .ant-upload-list-item,
    ant-upload-list-item-uploading,
    ant-upload-list-item-list-type-picture-card {
      height: 50px;
      width: 320px;
      padding: 0;
      border: none;
    }
  }
`;

type CustomUploadType = {
  file: File;
  onError: any;
  onSuccess: any;
};

const ImgBox = styled.div`
  width: 320px;
  height: 50px;
`;

const ImgStyle = styled.img`
  width: 320px;
  height: 50px;
`;

type SetAdType = {
  redirectionUrl: string;
};

export const AdManager: FC = () => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valueInput, setValueInput] = useState('');

  const [setAd] = useMutation((setAd: SetAdType) => FirebaseService.setAd(setAd.redirectionUrl), {
    onSuccess: () => {
      queryCache.invalidateQueries([AD]);
    },
  });

  const beforeUpload = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  // @ts-ignore
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadButton = <div>{loading ? <LoadingOutlined /> : <img src={plus} alt="plus img" />}</div>;

  const customUpload = async ({ onError, onSuccess, file }: CustomUploadType) => {
    const storage = firebase.storage();
    const metadata = {
      contentType: 'image/png',
    };
    const storageRef = await storage.ref();
    const imgFile = storageRef.child(`AdImage`);
    try {
      setLoading(true);
      const image = await imgFile.put(file, metadata);
      setLoading(false);
      onSuccess(image, file);
    } catch (e) {
      onError(e);
    }
  };

  return (
    <MainComponent>
      <TopNav isWithBackArrow>Ad Manager</TopNav>
      <AdMainBox>
        <AdPreviewBox> UPLOAD 320X50 AD </AdPreviewBox>
        <UploadDiv className="uploadAds">
          <UploadAdsStyle
            listType="picture-card"
            className="avatar-uploader"
            fileList={fileList}
            beforeUpload={beforeUpload}
            customRequest={customUpload}
            isImageUrl={() => false}
            onChange={handleChange}
          >
            {fileList.length < 1 && uploadButton}
          </UploadAdsStyle>
        </UploadDiv>
        <AdPreviewBox> AD REDIRECTION LINK </AdPreviewBox>
        <InputAndLink>
          <InputStyle
            placeholder="Redirection link"
            size="large"
            value={valueInput}
            onChange={e => setValueInput(e.target.value)}
          />
          <StyleLinkOutlined />
        </InputAndLink>
        <AdPreviewBox>AD PREVIEW</AdPreviewBox>
        <AdsComponentStyle>
          <ImgBox>
            {/*
  // @ts-ignore */}
            <ImgStyle src={fileList[0] && fileList[0].originFileObj} alt="img" />
          </ImgBox>
        </AdsComponentStyle>
        <ButtonStyle
          TextInButton="Save ad"
          onClick={async () => {
            await setAd({ redirectionUrl: valueInput });
          }}
        />
      </AdMainBox>
    </MainComponent>
  );
};
