import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

export interface ButtonInterface extends ButtonProps {
  TextInButton: string;
  onClick?: any;
}

const ButtonStyle = styled(Button)`
  && {
    border-radius: 2px;
    background-color: ${props => props.theme.colors.main.primary};
    ${props => props.theme.typography.body1};
    color: #ffffff;
  }
`;

export const LoginButton: FC<ButtonInterface> = ({ TextInButton, ...rest }) => {
  return (
    <ButtonStyle size="large" {...rest}>
      {TextInButton}
    </ButtonStyle>
  );
};
