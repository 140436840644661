import React, { useState, FC } from 'react';
import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { LoginButton } from '../Button/LoginButton';
import { queryCache, useMutation } from 'react-query';
import FirebaseService from '../../global/Firebase/FirebaseService';
import { PRIVATE_LEAGUE } from '../../helpers/RequestKeys';
import { useParams } from 'react-router-dom';
import SpinnerWithOverlay from '../SpinnerWithOverlay/SpinnerWithOverlay';

const EditText = styled.div`
  width: 100%;
  ${props => props.theme.typography.overline};
  margin: 18px 0;
`;

const StyledTextArea = styled(AntInput.TextArea)`
  && {
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

const RegulationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonRight = styled(LoginButton)`
  background-color: ${props => props.theme.colors.main.primary};
  color: ${props => props.theme.colors.utils.text.light};
  border-radius: 2px;
  float: right;
`;

const SaveRegulationsButton = styled(ButtonRight)`
  width: 170px;
  align-self: flex-end;
  margin-top: 8px;
`;

type RegulationsType = {
  isUserAnOwner: boolean;
  retrievedRegulations: string;
};

export const Regulations: FC<RegulationsType> = ({ isUserAnOwner, retrievedRegulations }) => {
  const { pin } = useParams();
  const [regulations, setRegulations] = useState(retrievedRegulations);
  const [setPrivateLeagueRegulations, { isLoading }] = useMutation(
    () => FirebaseService.setPrivateLeagueRegulations(pin, regulations),
    {
      onSuccess: () => {
        queryCache.invalidateQueries([PRIVATE_LEAGUE, pin]);
      },
    }
  );

  return (
    <>
      <EditText>REGULATIONS</EditText>
      {isLoading && <SpinnerWithOverlay />}
      {!isUserAnOwner ? (
        <p>{regulations}</p>
      ) : (
        <RegulationsWrapper>
          <StyledTextArea
            autoSize={{ minRows: 4 }}
            value={regulations}
            onChange={e => setRegulations(e.target.value)}
          />
          <SaveRegulationsButton TextInButton="Save regulations" onClick={setPrivateLeagueRegulations} />
        </RegulationsWrapper>
      )}
    </>
  );
};
