import React, { FunctionComponent, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { APP_ROUTES } from './routes';
import { Action } from 'pages/Action/Action';
import { CreateAccount } from '../../pages/CreateAccount/CreateAccount';
import { NewPassword } from '../../pages/NewPassword/NewPassword';
import { ForgotPassword } from '../../pages/ForgotPassword/ForgotPassword';
import { DashBoardMenu } from '../../pages/DashboardMenu/DashboardMenu';
import { LeagueMenu } from '../../pages/LeagueMenu/LeagueMenu';
import { CreateLeague } from '../../pages/CreateLeague/CreateLeague';
import { LeaguesData } from '../../pages/LeaguesData/LeaguesData';
import { DashboardLeaguesName } from '../../pages/DashboardLeaguesName/DashboardLeaguesName';
import { DashboardMain } from '../../pages/Home/Home';
import { LeaguesCheck } from '../../pages/Leagues/LeaguesCheck';
import { AdminDashboard } from '../../pages/AdminDashboard/AdminDashboard';
import { UserLogin } from '../../pages/Login/UserLogin';
import { AdManager } from '../../pages/AdManager/AdManager';
import { LeaguesCheckPreview } from '../../pages/LeaguesCheck/LeaguesCheck';
import { LeagueMenuPreview } from '../../pages/LeagueMenu/LeagueMenuPreview';
import { LeaguePinBet } from '../../pages/LeaguesData/LeaguePinBet';
import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute';
import { GameMenuPreview } from '../../components/LeagueName/GameMenuPreview';

export const AppRouter: FunctionComponent = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path={APP_ROUTES.ROOT} exact component={UserLogin} />
        <PrivateRoute path={APP_ROUTES.HOME} exact component={DashboardMain} />
        <Route path={APP_ROUTES.LOGIN} exact component={UserLogin} />
        <Route path={APP_ROUTES.CREATE_ACCOUNT} exact component={CreateAccount} />
        <Route path={APP_ROUTES.NEW_PASSWORD} exact component={NewPassword} />
        <Route path={APP_ROUTES.FORGOT_PASSWORD} exact component={ForgotPassword} />
        <PrivateRoute path={APP_ROUTES.CREATE_LEAGUE} exact component={CreateLeague} />
        <PrivateRoute path={APP_ROUTES.LEAGUE(':name', ':pin')} exact component={LeagueMenu} />
        <PrivateRoute path={APP_ROUTES.LEAGUE(':name', ':pin', ':tabName')} exact component={LeagueMenu} />
        <PrivateRoute
          path={APP_ROUTES.LEAGUE_PREVIEW_GAME(':name', ':pin', ':userName')}
          exact
          component={GameMenuPreview}
        />
        <PrivateRoute path={APP_ROUTES.DASHBOARD_MENU} exact component={DashBoardMenu} />
        <PrivateRoute path={APP_ROUTES.LEAGUES_DATA} exact component={LeaguesData} />
        <Route path={APP_ROUTES.ACTION} exact component={Action} />
        <Route path={APP_ROUTES.DASHBOARD_LOGIN} exact component={AdminDashboard} />
        <PrivateRoute path={APP_ROUTES.DASHBOARD_LEAGUES_ID(':name', ':id')} exact component={DashboardLeaguesName} />
        <PrivateRoute path={APP_ROUTES.LEAGUES} exact component={LeaguesCheck} />
        <PrivateRoute path={APP_ROUTES.LEAGUES_CHECK} exact component={LeaguesCheckPreview} />
        <PrivateRoute path={APP_ROUTES.LEAGUE_MENU_PREVIEW} exact component={LeagueMenuPreview} />
        <PrivateRoute
          path={APP_ROUTES.LEAGUE_PIN_PLAY(':privateLeagueName', ':pin', ':leagueName', ':leagueId')}
          exact
          component={LeaguePinBet}
        />
        <PrivateRoute path={APP_ROUTES.AD_MANAGER} exact component={AdManager} />
        <PrivateRoute path={APP_ROUTES.JOIN_LEAGUE(`:pin`)} exact component={DashboardMain} />
        <Route component={UserLogin} />
      </Switch>
    </>
  );
};
