import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { LoginButton } from 'components/Button/LoginButton';
import { UnlockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons/lib';
import { APP_ROUTES } from 'global/AppRouter/routes';
import { Card } from 'components/Card/Card';
import { boolean, InferType, object, ref, string } from 'yup';
import { Form, Formik } from 'formik';
import { useFirebase } from 'global/Firebase';
import { FormikInput } from 'components/FormikFields/FormikInput/FormikInput';
import FormikCheckbox from 'components/FormikFields/FormikChecbox/FormikCheckbox';
import FirebaseService from '../../global/Firebase/FirebaseService';
import { TopNavWithLogo } from '../../components/TopNavWithLogo/TopNavWithLogo';
import SpinnerWithOverlay from '../../components/SpinnerWithOverlay/SpinnerWithOverlay';
import { Modal } from 'antd';
import { vh } from '../../helpers/styled';

const Caption = styled.span(props => props.theme.typography.caption);

const MainComponent = styled.div`
  padding: 28px 16px 0 16px;
`;

const GreyCard = styled(Card)`
  padding: 24px;
  margin: 0px;
`;

const InputsComponent = styled.div`
  margin-top: 24px;
  flex-direction: column;
`;

const FirstInputStyle = styled(FormikInput)`
  border-radius: 2px;
  margin-bottom: 24px;
`;

const UserOutlinedStyle = styled(UserOutlined)`
  && {
    color: ${props => props.theme.colors.main.secondary};
  }
`;

const RegisterCaption = styled(Caption)`
  text-align: center;
  margin-top: 32px;
`;

const UnlockOutlinedStyle = styled(UnlockOutlined)`
  && {
    color: ${props => props.theme.colors.main.secondary};
  }
`;

const MailOutlinedStyle = styled(MailOutlined)`
  && {
    color: ${props => props.theme.colors.main.secondary};
  }
`;

const PasswordBorder = styled(FormikInput)`
  border-radius: 2px;
  margin-bottom: 24px;
`;

const StyledCheckbox = styled(FormikCheckbox)`
  && {
    text-align: left;
    align-self: start;
    margin-top: 16px;
  }
`;

const LinkStyleLeft = styled(Link)`
  margin-left: 2px;
  color: ${props => props.theme.colors.main.secondary};
  text-decoration: none;
`;

const RegisterButtonStyle = styled(LoginButton)`
  && {
    margin-top: 24px;
  }
`;

const StyledErrorMessage = styled.div`
  color: ${props => props.theme.colors.functional.error};
`;

const validationSchema = object({
  email: string()
    .email()
    .required(),
  playerName: string().required(),
  password: string().required(),
  repeatPassword: string()
    .oneOf([ref('password')], "Passwords doesn't match")
    .required(),
  termsAccepted: boolean()
    .required('The terms and conditions must be accepted.')
    .notOneOf([false], 'The terms and conditions must be accepted.'),
}).defined();

type FormValues = InferType<typeof validationSchema>;

const ContentWrapper = styled.div`
  max-height: ${vh(70)};
  overflow: auto;
`;

const initialValues: FormValues = {
  email: '',
  playerName: '',
  password: '',
  repeatPassword: '',
  termsAccepted: false,
};

export const privacyPolicyConfig = {
  title: 'Privacy Policy',
  content: (
    <>
      <ContentWrapper>
        This is the privacy notice of PredictTheLeagues.com, a trading name of Discovery Analytic Ltd. In this document,
        &quot;we&quot;, &quot;our&quot;, or &quot;us&quot; refer to PredictTheLeagues.com, a trading name of Discovery
        Analytic Ltd. We are company number 11410566 registered in England. Our registered office is at 32 Cemetery Road
        LS28 7HH. Introduction This privacy notice aims to inform you about how we collect and process any information
        that we collect from you, or that you provide to us. It covers information that could identify you (“personal
        information”) and information that could not. In the context of the law and this notice, “process” means
        collect, store, transfer, use or otherwise act on information. It tells you about your privacy rights and how
        the law protects you. We are committed to protecting your privacy and the confidentiality of your personal
        information. Our policy is not just an exercise in complying with the law, but a continuation of our respect for
        you and your personal information. We undertake to preserve the confidentiality of all information you provide
        to us, and hope that you reciprocate. Our policy complies with the Data Protection Act 2018 (Act) accordingly
        incorporating the EU General Data Protection Regulation (GDPR). The law requires us to tell you about your
        rights and our obligations to you in regard to the processing and control of your personal data. We do this now,
        by requesting that you read the information provided at http://www.knowyourprivacyrights.org Except as set out
        below, we do not share, or sell, or disclose to a third party, any information collected through our website. 1.
        Data Protection Officer We have appointed a data protection officer (DPO) who is responsible for ensuring that
        our policy is followed. If you have any questions about this privacy notice, including any requests to exercise
        your legal rights, please contact our DPO, DPO@PredictTheLeagues.com 2. Data we process We may collect, use,
        store and transfer different kinds of personal data about you. We have collated these into groups as follows:
        Your identity includes information such as first name, last name, title, date of birth, and other identifiers
        that you may have provided at some time. Your contact information includes information such as billing address,
        delivery address, email address, telephone numbers and any other information you have given to us for the
        purpose of communication or meeting. Your financial data includes information such as your bank account and
        payment card details. Transaction data includes details about payments or communications to and from you and
        information about products and services you have purchased from us. Technical data includes your internet
        protocol (IP) address, browser type and version, time zone setting and location, browser plug-in types and
        versions, operating system and platform and other technology on the devices you use to access this website. Your
        profile includes information such as your username and password, purchases or orders made by you, your
        interests, preferences, feedback and survey responses. Marketing data includes your preferences in receiving
        marketing from us; communication preferences; responses and actions in relation to your use of our services. We
        may aggregate anonymous data such as statistical or demographic data for any purpose. Anonymous data is data
        that does not identify you as an individual. Aggregated data may be derived from your personal data but is not
        considered personal information in law because it does not reveal your identity. For example, we may aggregate
        profile data to assess interest in a product or service. However, if we combine or connect aggregated data with
        your personal information so that it can identify you in any way, we treat the combined data as personal
        information and it will be used in accordance with this privacy notice. 3. Special personal information Special
        personal information is data about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
        orientation, political opinions, trade union membership, information about your health and genetic and biometric
        data. It also includes information about criminal convictions and offences. We do not collect any special
        personal information about you. 4. If you do not provide personal information we need Where we need to collect
        personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when
        requested, we may not be able to perform that contract. In that case, we may have to stop providing a service to
        you. If so, we will notify you of this at the time. The bases on which we process information about you The law
        requires us to determine under which of six defined bases we process different categories of your personal
        information, and to notify you of the basis for each category. If a basis on which we process your personal
        information is no longer relevant then we shall immediately stop processing your data. If the basis changes then
        if required by law we shall notify you of the change and of any new basis under which we have determined that we
        can continue to process your information. 5. Information we process because we have a contractual obligation
        with you When you create an account on our website, buy a product or service from us, or otherwise agree to our
        terms and conditions, a contract is formed between you and us. In order to carry out our obligations under that
        contract we must process the information you give us. Some of this information may be personal information. We
        may use it in order to:  verify your identity for security purposes  sell products to you  provide you with
        our services  provide you with suggestions and advice on products, services and how to obtain the most from
        using our website We process this information on the basis there is a contract between us, or that you have
        requested we use the information before we enter into a legal contract. We shall continue to process this
        information until the contract between us ends or is terminated by either party under the terms of the contract.
        6. Information we process with your consent Through certain actions when otherwise there is no contractual
        relationship between us, such as when you browse our website or ask us to provide you more information about our
        business, including our products and services, you provide your consent to us to process information that may be
        personal information. Wherever possible, we aim to obtain your explicit consent to process this information, for
        example, by asking you to agree to our use of cookies. If you have given us explicit permission to do so, we may
        from time to time pass your name and contact information to selected associates whom we consider may provide
        services or products you would find useful. We continue to process your information on this basis until you
        withdraw your consent or it can be reasonably assumed that your consent no longer exists. You may withdraw your
        consent at any time by instructing us help@PredictTheLeagues.com. However, if you do so, you may not be able to
        use our website or our services further. 7. Information we process for the purposes of legitimate interests We
        may process information on the basis there is a legitimate interest, either to you or to us, of doing so. Where
        we process your information on this basis, we do after having given careful consideration to:  whether the same
        objective could be achieved through other means  whether processing (or not processing) might cause you harm 
        whether you would expect us to process your data, and whether you would, in the round, consider it reasonable to
        do so For example, we may process your data on this basis for the purposes of:  record-keeping for the proper
        and necessary administration of our business  responding to unsolicited communication from you to which we
        believe you would expect a response  protecting and asserting the legal rights of any party  insuring against
        or obtaining professional advice that is required to manage business risk  protecting your interests where we
        believe we have a duty to do so 8. Information we process because we have a legal obligation Sometimes, we must
        process your information in order to comply with a statutory obligation. For example, we may be required to give
        information to legal authorities if they so request or if they have the proper authorisation such as a search
        warrant or court order. This may include your personal information. Specific uses of information you provide to
        us 9. Information provided on the understanding that it will be shared with a third party Our website allows you
        to post information with a view to that information being read, copied, downloaded, or used by other people.
        Examples include:  posting a message our forum  tagging an image  clicking on an icon next to another
        visitor’s message to convey your agreement, disagreement or thanks In posting personal information, it is up to
        you to satisfy yourself about the privacy level of every person who might use it. We do not specifically use
        this information except to allow it to be displayed or shared. We do store it, and we reserve a right to use it
        in the future in any way we decide. Once your information enters the public domain, we have no control over what
        any individual third party may do with it. We accept no responsibility for their actions at any time. Provided
        your request is reasonable and there is no legal basis for us to retain it, then at our discretion we may agree
        to your request to delete personal information that you have posted. You can make a request by contacting us at
        help@PredictTheLeagues.com. 10. Complaints regarding content on our website Our website is a publishing medium.
        Anyone may register and then publish information about himself, herself or some other person. We do not moderate
        or control what is posted. If you complain about any of the content on our website, we shall investigate your
        complaint. If we feel it is justified or if we believe the law requires us to do so, we shall remove the content
        while we investigate. Free speech is a fundamental right, so we have to make a judgment as to whose right will
        be obstructed: yours, or that of the person who posted the content that offends you. If we think your complaint
        is vexatious or without any basis, we shall not correspond with you about it. 11. Information relating to your
        method of payment Payment information is never taken by us or transferred to us either through our website or
        otherwise. Our employees and contractors never have access to it. At the point of payment, you are transferred
        to a secure page on the website of an external payment service provider. That page may be branded to look like a
        page on our website, but it is not controlled by us. 12. Job application and employment If you send us
        information in connection with a job application, we may keep it for up to three years in case we decide to
        contact you at a later date. If we employ you, we collect information about you and your work from time to time
        throughout the period of your employment. This information will be used only for purposes directly relevant to
        your employment. After your employment has ended, we will keep your file for six years before destroying or
        deleting it. 13. Communicating with us When you contact us, whether by telephone, through our website or by
        e-mail, we collect the data you have given to us in order to reply with the information you need. We record your
        request and our reply in order to increase the efficiency of our business. We keep personally identifiable
        information associated with your message, such as your name and email address so as to be able to track our
        communications with you to provide a high quality service. 14. Complaining When we receive a complaint, we
        record all the information you have given to us. We use that information to resolve your complaint. If your
        complaint reasonably requires us to contact some other person, we may decide to give to that other person some
        of the information contained in your complaint. We do this as infrequently as possible, but it is a matter for
        our sole discretion as to whether we do give information, and if we do, what that information is. We may also
        compile statistics showing information obtained from this source to assess the level of service we provide, but
        not in a way that could identify you or any other person. 15. Affiliate and business partner information This is
        information given to us by you in your capacity as an affiliate of us or as a business partner. It allows us to
        recognise visitors that you have referred to us, and to credit to you commission due for such referrals. It also
        includes information that allows us to transfer commission to you. The information is not used for any other
        purpose. We undertake to preserve the confidentiality of the information and of the terms of our relationship.
        We expect any affiliate or partner to agree to reciprocate this policy. Use of information we collect through
        automated systems when you visit our website 16. Cookies Cookies are small text files that are placed on your
        computer&#39;s hard drive by your web browser when you visit any website. They allow information gathered on one
        web page to be stored until it is needed for use on another, allowing a website to provide you with a
        personalised experience and the website owner with statistics about how you use the website so that it can be
        improved. Some cookies may last for a defined period of time, such as one day or until you close your browser.
        Others last indefinitely. Your web browser should allow you to delete any you choose. It also should allow you
        to prevent or limit their use. Our website uses cookies. They are placed by software that operates on our
        servers, and by software operated by third parties whose services we use. When you first visit our website, we
        ask you whether you wish us to use cookies. If you choose not to accept them, we shall not use them for your
        visit except to record that you have not consented to their use for any other purpose. If you choose not to use
        cookies or you prevent their use through your browser settings, you will not be able to use all the
        functionality of our website. We use cookies in the following ways:  to track how you use our website  to
        record whether you have seen specific messages we display on our website  to keep you signed in to our website
         to record your answers to surveys and questionnaires on our site while you complete them  to record the
        conversation thread during a live chat with our support team 17. Personal identifiers from your browsing
        activity Requests by your web browser to our servers for web pages and other content on our website are
        recorded. We record information such as your geographical location, your Internet service provider and your IP
        address. We also record information about the software you are using to browse our website, such as the type of
        computer or device and the screen resolution. We use this information in aggregate to assess the popularity of
        the webpages on our website and how we perform in providing content to you. If combined with other information
        we know about you from previous visits, the data possibly could be used to identify you personally, even if you
        are not signed in to our website. 18. Our use of re-marketing Re-marketing involves placing a cookie on your
        computer when you browse our website in order to be able to serve to you an advert for our products or services
        when you visit some other website. We may use a third party to provide us with re-marketing services from time
        to time. If so, then if you have consented to our use of cookies, you may see advertisements for our products
        and services on other websites. Disclosure and sharing of your information 19. Information we obtain from third
        parties Although we do not disclose your personal information to any third party (except as set out in this
        notice), we sometimes receive data that is indirectly made up from your personal information from third parties
        whose services we use. No such information is personally identifiable to you. 20. Third party advertising on our
        website Third parties may advertise on our website. In doing so, those parties, their agents or other companies
        working for them may use technology that automatically collects information about you when their advertisement
        is displayed on our website. They may also use other technology such as cookies or JavaScript to personalise the
        content of, and to measure the performance of their adverts. We do not have control over these technologies or
        the data that these parties obtain. Accordingly, this privacy notice does not cover the information practices of
        these third parties. 21. Credit reference To assist in combating fraud, we share information with credit
        reference agencies, so far as it relates to clients or customers who instruct their credit card issuer to cancel
        payment to us without having first provided an acceptable reason to us and given us the opportunity to refund
        their money. 22. Data may be processed outside the European Union Our websites are hosted in UK. We may also use
        outsourced services in countries outside the European Union from time to time in other aspects of our business.
        Accordingly data obtained within the UK or any other country could be processed outside the European Union. We
        use the following safeguards with respect to data transferred outside the European Union:  the processor abides
        by the same binding corporate rules regarding data processing. Control over your own information 23. Your duty
        to inform us of changes It is important that the personal data we hold about you is accurate and current. Please
        keep us informed if your personal data changes. 24. Access to your personal information At any time you may
        review or update personally identifiable information that we hold about you, by signing in to your account on
        our website. To obtain a copy of any information that is not provided on our website you should contact us to
        make that request. After receiving the request, we will tell you when we expect to provide you with the
        information, and whether we require any fee for providing it to you. 25. Removal of your information If you wish
        us to remove personally identifiable information from our website, you should contact us to make your request.
        This may limit the service we can provide to you. 26. Verification of your information When we receive any
        request to access, edit or delete personal identifiable information we shall first take reasonable steps to
        verify your identity before granting you access or otherwise taking any action. This is important to safeguard
        your information. Other matters 27. Use of site by children We do not sell products or provide services for
        purchase by children, nor do we market to children. If you are under 18, you may use our website only with
        consent from a parent or guardian 28. How you can complain If you are not happy with our privacy policy or if
        you have any complaint then you should tell us. If a dispute is not settled then we hope you will agree to
        attempt to resolve it by engaging in good faith with us in a process of mediation or arbitration. If you are in
        any way dissatisfied about how we process your personal information, you have a right to lodge a complaint with
        the Information Commissioner&#39;s Office (ICO). This can be done at https://ico.org.uk/make-a- complaint/. We
        would, however, appreciate the opportunity to talk to you about your concern before you approach the ICO. 29.
        Retention period for personal data Except as otherwise mentioned in this privacy notice, we keep your personal
        information only for as long as required by us:  to provide you with the services you have requested;  to
        comply with other law, including for the period demanded by our tax authorities;  to support a claim or defence
        in court. 30. Compliance with the law Our privacy policy has been compiled so as to comply with the law of every
        country or legal jurisdiction in which we aim to do business. If you think it fails to satisfy the law of your
        jurisdiction, we should like to hear from you. However, ultimately it is your choice as to whether you wish to
        use our website. 31. Review of this privacy policy We may update this privacy notice from time to time as
        necessary. The terms that apply to you are those posted here on our website on the day you use our website. We
        advise you to print a copy for your records. If you have any question regarding our privacy policy, please
        contact us.
      </ContentWrapper>
    </>
  ),
};

export const TOSConfig = {
  title: 'Term of Service',
  content: (
    <>
      <ContentWrapper>
        Introduction These terms and conditions apply between you, the User of this Website (including any sub-domains,
        unless expressly excluded by their own terms and conditions), and  PredictTheLeagues.com a trading name of
        Discovery Analytic Ltd,  the owner and operator of this Website. Please read these terms and conditions
        carefully, as they affect your legal rights. Your agreement to comply with and be bound by these terms and
        conditions is deemed to occur upon your first use of the Website. If you do not agree to be bound by these terms
        and conditions, you should stop using the Website immediately. In these terms and
        conditions, User or Users means any third party that accesses the Website and is not either (i) employed
        by  PredictTheLeagues.com a trading name of Discovery Analytic Ltd  and acting in the course of their employment
        or (ii) engaged as a consultant or otherwise providing services to  PredictTheLeagues.com a trading name of
        Discovery Analytic Ltd  and accessing the Website in connection with the provision of such services. You must be
        at least 18 years of age to use this Website. By using the Website and agreeing to these terms and conditions,
        you represent and warrant that you are at least 18 years of age. Intellectual property and acceptable use 1. All
        Content included on the Website, unless uploaded by Users, is the property of  PredictTheLeagues.com a trading
        name of Discovery Analytic Ltd,  our affiliates or other relevant third parties. In these terms and conditions,
        Content means any text, graphics, images, audio, video, software, data compilations, page layout, underlying
        code and software and any other form of information capable of being stored in a computer that appears on or
        forms part of this Website, including any such content uploaded by Users. By continuing to use the Website you
        acknowledge that such Content is protected by copyright, trademarks, database rights and other intellectual
        property rights. Nothing on this site shall be construed as granting, by implication, estoppel, or otherwise,
        any license or right to use any trademark, logo or service mark displayed on the site without the owner&#39;s
        prior written permission 2. You may, for your own personal, non-commercial use only, do the following: a.
        retrieve, display and view the Content on a computer screen 3. You must not otherwise reproduce, modify, copy,
        distribute or use for commercial purposes any Content without the written permission of  PredictTheLeagues.com a
        trading name of Discovery Analytic Ltd. 4. You acknowledge that you are responsible for any Content you may
        submit via the Website, including the legality, reliability, appropriateness, originality and copyright of any
        such Content. You may not upload to, distribute or otherwise publish through the Website any Content that (i) is
        confidential, proprietary, false, fraudulent, libellous, defamatory, obscene, threatening, invasive of privacy
        or publicity rights, infringing on intellectual property rights, abusive, illegal or otherwise objectionable;
        (ii) may constitute or encourage a criminal offence, violate the rights of any party or otherwise give rise to
        liability or violate any law; or (iii) may contain software viruses, political campaigning, chain letters, mass
        mailings, or any form of &quot;spam.&quot; You may not use a false email address or other identifying
        information, impersonate any person or entity or otherwise mislead as to the origin of any content. You may not
        upload commercial content onto the Website. 5. You represent and warrant that you own or otherwise control all
        the rights to the Content you post; that the Content is accurate; that use of the Content you supply does not
        violate any provision of these terms and conditions and will not cause injury to any person; and that you will
        indemnify  PredictTheLeagues.com a trading name of Discovery Analytic Ltd  for all claims resulting from Content
        you supply. Prohibited use 6. You may not use the Website for any of the following purposes: a. in any way which
        causes, or may cause, damage to the Website or interferes with any other person&#39;s use or enjoyment of the
        Website; b. in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise
        objectionable or in breach of any applicable law, regulation, governmental order; c. making, transmitting or
        storing electronic copies of Content protected by copyright without the permission of the owner. Registration 7.
        You must ensure that the details provided by you on registration or at any time are correct and complete. 8. You
        must inform us immediately of any changes to the information that you provide when registering by updating your
        personal details to ensure we can communicate with you effectively. 9. We may suspend or cancel your
        registration with immediate effect for any reasonable purposes or if you breach these terms and conditions. 10.
        You may cancel your registration at any time by informing us in writing to the address at the end of these terms
        and conditions. If you do so, you must immediately stop using the Website. Cancellation or suspension of your
        registration does not affect any statutory rights. Password and security 11. When you register on this Website,
        you will be asked to create a password, which you should keep confidential and not disclose or share with
        anyone. 12. If we have reason to believe that there is or is likely to be any misuse of the Website or breach of
        security, we may require you to change your password or suspend your account. Links to other websites 13. This
        Website may contain links to other sites. Unless expressly stated, these sites are not under the control
        of  PredictTheLeagues.com a trading name of Discovery Analytic Ltd  or that of our affiliates. 14. We assume no
        responsibility for the content of such Websites and disclaim liability for any and all forms of loss or damage
        arising out of the use of them. 15. The inclusion of a link to another site on this Website does not imply any
        endorsement of the sites themselves or of those in control of them. Privacy Policy 16. Use of the Website is
        also governed by our Privacy Policy, which is incorporated into these terms and conditions by this reference. To
        view the Privacy Policy, please click on the following: www.PredictTheLeagues.com/privacypolicy. Availability of
        the Website and disclaimers 17. Any online facilities, tools, services or information
        that  PredictTheLeagues.com a trading name of Discovery Analytic Ltd  makes available through the Website
        (the Service) is provided &quot;as is&quot; and on an &quot;as available&quot; basis. We give no warranty that
        the Service will be free of defects and/or faults. To the maximum extent permitted by the law, we provide no
        warranties (express or implied) of fitness for a particular purpose, accuracy of information, compatibility and
        satisfactory quality.  PredictTheLeagues.com a trading name of Discovery Analytic Ltd  is under no obligation to
        update information on the Website. 18. Whilst  PredictTheLeagues.com a trading name of Discovery Analytic
        Ltd  uses reasonable endeavours to ensure that the Website is secure and free of errors, viruses and other
        malware, we give no warranty or guaranty in that regard and all Users take responsibility for their own
        security, that of their personal details and their computers. 19.  PredictTheLeagues.com a trading name of
        Discovery Analytic Ltd  accepts no liability for any disruption or non-availability of the Website. 20.
         PredictTheLeagues.com a trading name of Discovery Analytic Ltd  reserves the right to alter, suspend or
        discontinue any part (or the whole of) the Website including, but not limited to, any products and/or services
        available. These terms and conditions shall continue to apply to any modified version of the Website unless it
        is expressly stated otherwise. Limitation of liability 21. Nothing in these terms and conditions will: (a) limit
        or exclude our or your liability for death or personal injury resulting from our or your negligence, as
        applicable; (b) limit or exclude our or your liability for fraud or fraudulent misrepresentation; or (c) limit
        or exclude any of our or your liabilities in any way that is not permitted under applicable law. 22. To the
        extent that the Website and Content are provided free of charge, we will not be liable to you for any loss or
        damage of any kind. 23. We will not be liable to you in respect of any losses arising out of events beyond our
        reasonable control. 24. To the maximum extent permitted by law,  PredictTheLeagues.com a trading name of
        Discovery Analytic Ltd  accepts no liability for any of the following: a. any business losses, such as loss of
        profits, income, revenue, anticipated savings, business, contracts, goodwill or commercial opportunities; b.
        loss or corruption of any data, database or software; c. any special, indirect or consequential loss or damage.
        General 25. You may not transfer any of your rights under these terms and conditions to any other person. We may
        transfer our rights under these terms and conditions where we reasonably believe your rights will not be
        affected. 26. These terms and conditions may be varied by us from time to time. Such revised terms will apply to
        the Website from the date of publication. Users should check the terms and conditions regularly to ensure
        familiarity with the then current version. 27. These terms and conditions  together with the Privacy
        Policy    contain the whole agreement between the parties relating to its subject matter and supersede all prior
        discussions, arrangements or agreements that might have taken place in relation to the terms and conditions. 28.
        The Contracts (Rights of Third Parties) Act 1999 shall not apply to these terms and conditions and no third
        party will have any right to enforce or rely on any provision of these terms and conditions. 29. If any court or
        competent authority finds that any provision of these terms and conditions (or part of any provision) is
        invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to
        be deleted, and the validity and enforceability of the other provisions of these terms and conditions will not
        be affected. 30. Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy
        will be deemed a waiver of that, or any other, right or remedy. 31. This Agreement shall be governed by and
        interpreted according to the law of England and Wales and all disputes arising under the Agreement (including
        non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the English and
        Welsh courts. PredictTheLeagues.com a trading name of Discovery Analytic Ltd  details 32. PredictTheLeagues.com
        a trading name of Discovery Analytic Ltd is a company incorporated in England and Wales with registered
        number 11410566 whose registered address is 32 Cemetery Road, LS28 7HH and it  operates the
        Website www.PredictTheLeagues.com.  The registered VAT number is 297594532. You can
        contact PredictTheLeagues.com a trading name of Discovery Analytic Ltd by email on help@PredictTheLeagues.com.
        Attribution 33. These terms and conditions were created using a document from Rocket
        Lawyer (https://www.rocketlawyer.co.uk).
      </ContentWrapper>
    </>
  ),
};

export const CreateAccount: FC = () => {
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const firebase = useFirebase();
  const history = useHistory();

  const [modal, contextHolder] = Modal.useModal();

  const handleRegister = async ({ email, playerName, password }: FormValues): Promise<void> => {
    try {
      setIsLoading(true);
      const data = await FirebaseService.createPlayer(playerName, password, email);
      setIsLoading(false);
      if (data) {
        localStorage.setItem('emailForSignIn', email);
        history.push(`${APP_ROUTES.LOGIN}?action=verificationEmailSent`);
      } else {
        const idToken = await firebase?.getCurrentUser()?.getIdToken();
        localStorage.setItem('idToken', idToken || '');
      }
    } catch (error) {
      setIsLoading(false);
      setApiError(error.message);
    }
  };

  return (
    <>
      {isLoading && <SpinnerWithOverlay />}
      {contextHolder}
      <TopNavWithLogo isWithBackArrow />
      <Formik initialValues={initialValues} onSubmit={handleRegister} validationSchema={validationSchema}>
        {() => (
          <Form>
            <MainComponent>
              <GreyCard>
                <h5>Create account</h5>
                <InputsComponent>
                  <FirstInputStyle placeholder="Email" name="email" prefix={<MailOutlinedStyle translate="yes" />} />
                  <FirstInputStyle
                    placeholder="User name"
                    name="playerName"
                    prefix={<UserOutlinedStyle translate="yes" />}
                  />
                  <PasswordBorder
                    type="password"
                    name="password"
                    placeholder="Password"
                    prefix={<UnlockOutlinedStyle translate="yes" />}
                  />
                  <PasswordBorder
                    type="password"
                    name="repeatPassword"
                    placeholder="Repeat Password"
                    prefix={<UnlockOutlinedStyle translate="yes" />}
                  />
                </InputsComponent>
                <StyledCheckbox name="termsAccepted">
                  I read and agree to{' '}
                  <span
                    onClick={() => {
                      modal.confirm(TOSConfig);
                    }}
                  >
                    <a>term of use</a>
                  </span>{' '}
                  and{' '}
                  <span onClick={() => modal.confirm(privacyPolicyConfig)}>
                    <a>privacy policy</a>.
                  </span>
                </StyledCheckbox>
                <RegisterButtonStyle TextInButton="Register" htmlType="submit" />
                <RegisterCaption>
                  Already have an account?
                  <LinkStyleLeft to={APP_ROUTES.LOGIN}>Login</LinkStyleLeft>
                </RegisterCaption>
                <StyledErrorMessage>{apiError}</StyledErrorMessage>
              </GreyCard>
            </MainComponent>
          </Form>
        )}
      </Formik>
    </>
  );
};
