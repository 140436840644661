import React, { FC } from 'react';
import { TopNav } from 'components/TopNav/TopNav';
import styled from 'styled-components';
import { Card } from '../../components/Card/Card';
import { LoginButton } from '../../components/Button/LoginButton';
import { APP_ROUTES } from '../../global/AppRouter/routes';
import { Link } from 'react-router-dom';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons/lib';

// todo: remove whole component because it is probably not even used. but double check is required

const mockedLeagues = [
  {
    position: '5',
    name: 'Johdsn',
    players: '6',
    pin: 'A32DSD',
  },
  {
    position: '4',
    name: 'Johasdn',
    players: '3',
    pin: 'A3223DSD',
  },
  {
    position: '3',
    name: 'Jewqohn',
    players: '7',
    pin: 'A32D45SD',
  },
];

const MainComponent = styled.div`
  padding: 48px 16px 0 16px;
`;

const GreyCard = styled(Card)`
  padding: 16px 20px 20px 24px;
  margin-bottom: 18px;
  text-align: left;
  flex-direction: column;
`;

const PlayersBox = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const ButtonRight = styled(LoginButton)`
  background-color: ${props => props.theme.colors.main.primary};
  color: ${props => props.theme.colors.utils.text.light};
  border-radius: 2px;
  float: right;
`;

const PinBox = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const PositionBox = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const StyleLink = styled(Link)`
  text-decoration: none;
`;

const StyleCaretUp = styled(CaretUpFilled)`
  color: ${props => props.theme.colors.main.primary};
  margin-left: 8px;
`;

const StyleCaretDown = styled(CaretDownFilled)`
  color: ${props => props.theme.colors.functional.error};
  margin-left: 8px;
`;

const CaretUpOrDown = () => {
  const x = 0;
  if (x === 0) {
    return <StyleCaretUp />;
  } else {
    return <StyleCaretDown />;
  }
};

const MarginBox = styled.div`
  margin-left: 8px;
`;

export const LeaguesCheckPreview: FC = () => {
  return (
    <>
      <TopNav isWithBackArrow={true}>Leagues</TopNav>
      <MainComponent>
        {mockedLeagues.map(leagues => (
          <GreyCard key="1">
            <h6>
              <b>{leagues.name}</b>
            </h6>
            <PositionBox>
              Position:<MarginBox>{leagues?.position}</MarginBox>
              <CaretUpOrDown />
            </PositionBox>
            <PlayersBox>
              Players:<MarginBox>{leagues.players}</MarginBox>
            </PlayersBox>
            <PinBox>
              Pin:
              <b>
                <MarginBox>{leagues.pin}</MarginBox>
              </b>
            </PinBox>
            <StyleLink to={APP_ROUTES.LEAGUE_MENU_PREVIEW}>
              <ButtonRight TextInButton="Preview" />
            </StyleLink>
          </GreyCard>
        ))}
      </MainComponent>
    </>
  );
};
