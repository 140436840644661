import React, { useState, FC, useEffect } from 'react';
import { Table } from 'antd';
import { RowSelectionType } from 'antd/lib/table/interface';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { REAL_LEAGUES } from '../../../helpers/RequestKeys';
import FirebaseService from '../../../global/Firebase/FirebaseService';
import { RealLeague } from '../../../beTypes';
import { CenteredSpinner } from '../../../components/CenteredSpinner/CenteredSpinner';

const StyledText = styled.div`
  ${props => props.theme.typography.subtitle1};
  font-weight: bold;
`;

const columns = [
  {
    title: <StyledText>Leagues</StyledText>,
    dataIndex: 'name',
    render: (text: string, league: RealLeague) => (
      <div>
        {league.logo && <img src={league.logo} style={{ width: '80px', marginRight: '8px' }} />}
        {text} ({league.country})
      </div>
    ),
  },
];

type CreateLeagueTablePropsType = {
  setChosenRealLeagueIds: (arg: number[]) => void;
};

export const CreateLeagueTable: FC<CreateLeagueTablePropsType> = ({ setChosenRealLeagueIds }) => {
  const [selectionType] = useState<RowSelectionType>('checkbox');
  const { data } = useQuery<any, any, any>(REAL_LEAGUES, FirebaseService.getRealLeagues);
  const [isSelectedAtRender, setIsSelectedAtRender] = useState([3501, 3502, 3495, 3456]);
  const realLeagues = data && (data.realLeagues as RealLeague[]);

  useEffect(() => {
    setChosenRealLeagueIds(isSelectedAtRender);
  });

  const rowSelection = {
    onChange: (selectedRowKeys: any) => {
      setChosenRealLeagueIds(selectedRowKeys);
      setIsSelectedAtRender(selectedRowKeys);
    },

    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      {realLeagues ? (
        <Table
          rowSelection={{
            type: selectionType,
            ...rowSelection,
            selectedRowKeys: isSelectedAtRender,
          }}
          columns={columns}
          dataSource={realLeagues.map(league => ({
            key: league.id,
            name: league.name,
            logo: league.logo,
            country: league.country,
            id: league.id,
          }))}
          pagination={false}
        />
      ) : (
        <CenteredSpinner />
      )}
    </div>
  );
};
