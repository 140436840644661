import React, { FC } from 'react';
import { Typography } from 'antd';
import { APP_ROUTES } from '../../global/AppRouter/routes';

const { Paragraph } = Typography;

export interface CopyLinkType {
  pin: string;
}

export const CopyLinkParagraph: FC<CopyLinkType> = ({ pin, children }) => {
  return (
    <Paragraph copyable={{ text: `${window.location.origin}${APP_ROUTES.JOIN_LEAGUE(pin)}` }}>{children}</Paragraph>
  );
};
