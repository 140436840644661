import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Input, notification } from 'antd';
import { LoginButton } from 'components/Button/LoginButton';
import { MailOutlined } from '@ant-design/icons/lib';
import { Card } from 'components/Card/Card';
import { useFirebase } from 'global/Firebase';
import { TopNavWithLogo } from '../../components/TopNavWithLogo/TopNavWithLogo';

const MainComponent = styled.div`
  padding: 160px 16px 0 16px;
`;

const GreyCard = styled(Card)`
  padding: 24px;
`;

const InputsComponent = styled.div`
  margin-top: 12px;
  flex-direction: column;
`;

const FirstInputMargin = styled.div`
  margin-bottom: 32px;
`;

const FirstInputStyle = styled(Input)`
  border-radius: 2px;
`;

const MailOutlinedStyle = styled(MailOutlined)`
  && {
    color: #1890ff;
  }
`;

export const ForgotPassword: FC = () => {
  const [email, setEmail] = useState('');
  const firebase = useFirebase();

  const handleSubmit = async () => {
    if (!email) return;
    await firebase.sendPasswordResetEmail(email);
    notification.success({
      message: 'Email has been sent',
      description: 'Please check your inbox or junk mail and click in the reset password link',
    });
  };

  return (
    <>
      <TopNavWithLogo isWithBackArrow />
      <MainComponent>
        <GreyCard>
          <h5>Forgot Password?</h5>
          <InputsComponent>
            <FirstInputMargin>
              <FirstInputStyle
                size="large"
                placeholder="Email"
                prefix={<MailOutlinedStyle translate="yes" />}
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </FirstInputMargin>
          </InputsComponent>
          <LoginButton TextInButton="Send" onClick={handleSubmit} />
        </GreyCard>
      </MainComponent>
    </>
  );
};
