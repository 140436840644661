import React from 'react';
import { TopNav } from 'components/TopNav/TopNav';
import styled from 'styled-components';
import { vh } from '../../helpers/styled';
import { Card } from '../../components/Card/Card';
import { APP_ROUTES } from '../../global/AppRouter/routes';
import { Link } from 'react-router-dom';
import FirebaseService from 'global/Firebase/FirebaseService';
import { CenteredSpinner } from 'components/CenteredSpinner/CenteredSpinner';
import { useQuery } from 'react-query';
import { REAL_LEAGUES } from '../../helpers/RequestKeys';
import { RealLeague } from '../../beTypes';
import _ from 'lodash';
import { TeamLogo } from 'components/TeamLogo/TeamLogo';

const MainComponent = styled.div`
  min-height: calc(${vh(100)} - 56px);
  padding: 20px;
`;

const GreyCard = styled(Card)`
  padding: 16px 25px;
  margin-bottom: 18px;
  color: ${props => props.theme.colors.utils.text.dark};
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const LeaguesData = () => {
  const { data } = useQuery<any, any, any>(REAL_LEAGUES, FirebaseService.getRealLeagues);

  const realLeagues = data && (data.realLeagues as RealLeague[]);

  return (
    <>
      <TopNav isWithBackArrow={true}>Leagues Data</TopNav>
      <MainComponent>
        {!realLeagues ? (
          <CenteredSpinner size="large" />
        ) : (
          <>
            {_.sortBy(realLeagues, ['name']).map(leaguesData => (
              <StyledLink
                key={leaguesData.id}
                to={APP_ROUTES.DASHBOARD_LEAGUES_ID(
                  `${leaguesData.name} (${leaguesData.country})`,
                  `${leaguesData.id}`
                )}
              >
                <GreyCard key="">
                  <TeamLogo logoUrl={leaguesData.logo} />
                  {`${leaguesData.name} (${leaguesData.country})`}
                </GreyCard>
              </StyledLink>
            ))}
          </>
        )}
      </MainComponent>
    </>
  );
};
