import { getUrlBuilder } from '../../helpers/buildUrl';
import axios from '../axios/axios';
import { BettingPlayerStatus, StandingsRange, TimePeriod } from '../../beTypes';

export interface IFirebaseService {
  API_CONSTS: {
    URL: string;
    END_POINTS: {
      LOGIN: string;
      LEAGUES: string;
      EXISTS: string;
      CREATE_USER: string;
      CREATE_LEAGUE: string;
      AD: string;
    };
  };
  urlBuilder: ReturnType<typeof getUrlBuilder>;
  login: (userName: string, password: string) => Promise<any>;
  isPlayerNameExisting: (userName: string) => Promise<boolean>;
  confirmUser: (userName: string, oobCode: string) => Promise<any>;
  getRealLeagues: () => Promise<any>;
  joinPrivateLeague: (pin: string) => Promise<any>;
  getPrivateLeagues: () => Promise<any>;
  createPrivateLeague: (name: string, chosenRealLeagueIds: number[], regulations: string) => Promise<any>;
  removePrivateLeague: (pin: string) => Promise<any>;
  getRealLeagueTeams: (arg: number) => Promise<any>;
  getPrivateLeagueDetails: (arg: string) => Promise<any>;
  participateInALeague: (arg: string) => Promise<any>;
  betLeague: (gameLeagueId: number, teamBets: { id: number; credits: number }[], pin: string) => Promise<any>;
  overrideLeagueTeamCredits: (credits: number, realLeagueId: number, teamId: number) => Promise<any>;
  createPlayer: (userName: string, password: string, email: string) => Promise<any>;
  setAd: (redirectionUrl: string) => Promise<any>;
  getAd: (redirectionUrl: string) => Promise<any>;
}

export class FirebaseService implements IFirebaseService {
  API_CONSTS = {
    URL: process.env.REACT_APP_API_URL as string,
    END_POINTS: {
      AD: 'ad',
      LOGIN: 'login',
      LEAGUES: 'real-leagues',
      EXISTS: 'exists',
      CREATE_USER: 'player',
      CREATE_LEAGUE: 'league',
      CONFIRM_USER: 'player/confirm',
      TEAMS: 'teams',
      TEAM: 'team',
      PRIVATE_LEAGUE: 'private-league',
      PRIVATE_LEAGUE_JOIN: 'private-league/join',
      PRIVATE_LEAGUES: 'private-leagues',
      SEND_REGISTRATION_EMAIL: 'send-registration-email',
    },
  };

  urlBuilder = getUrlBuilder(this.API_CONSTS.URL);

  login = async (): Promise<any> => {
    const url = this.urlBuilder(this.API_CONSTS.END_POINTS.LOGIN);
    return await axios.get(url);
  };

  getRealLeagues = async (): Promise<any> => {
    const url = this.urlBuilder(this.API_CONSTS.END_POINTS.LEAGUES);
    return await axios.get(url);
  };

  getPrivateLeagues = async (): Promise<any> => {
    const url = this.urlBuilder(this.API_CONSTS.END_POINTS.PRIVATE_LEAGUES);
    return await axios.get(url);
  };

  getPrivateLeagueDetails = async (pin: string): Promise<any> => {
    const url = this.urlBuilder([this.API_CONSTS.END_POINTS.PRIVATE_LEAGUE, pin]);
    return await axios.get(url);
  };

  getPrivateLeagueDetailsByUserName = async (pin: string, userName: string): Promise<any> => {
    const url = this.urlBuilder([this.API_CONSTS.END_POINTS.PRIVATE_LEAGUE, pin, userName]);
    return await axios.get(url);
  };

  getRealLeagueTeams = async (realLeagueId: number): Promise<any> => {
    const url = this.urlBuilder(`${this.API_CONSTS.END_POINTS.TEAMS}/${realLeagueId}`);
    return await axios.get(url);
  };

  overrideLeagueTeamCredits = async (credits: number, realLeagueId: number, teamId: number): Promise<any> => {
    const url = this.urlBuilder(`${this.API_CONSTS.END_POINTS.TEAM}/${realLeagueId}/${teamId}`);

    return await axios.post(url, {
      credits,
    });
  };

  joinPrivateLeague = async (pin: string): Promise<any> => {
    const url = this.urlBuilder(`${this.API_CONSTS.END_POINTS.PRIVATE_LEAGUE_JOIN}`);

    return await axios.post(url, {
      pin,
    });
  };

  betLeague = async (gameLeagueId: number, teamsBets: { id: number; credits: number }[], pin: string): Promise<any> => {
    const url = this.urlBuilder(`${this.API_CONSTS.END_POINTS.PRIVATE_LEAGUE}/${pin}/game-league`);

    return await axios.post(url, {
      gameLeagueId,
      teamsBets,
    });
  };

  createPrivateLeague = async (name: string, chosenRealLeagueIds: number[], regulations: string): Promise<any> => {
    const url = this.urlBuilder(`${this.API_CONSTS.END_POINTS.PRIVATE_LEAGUE}`);

    return await axios.post(url, {
      name,
      chosenRealLeagueIds,
      regulations,
    });
  };

  changePlayerStatus = async (pin: string, status: BettingPlayerStatus, userName: string): Promise<void> => {
    const url = this.urlBuilder([this.API_CONSTS.END_POINTS.PRIVATE_LEAGUE, pin, 'player']);

    return await axios.post(url, {
      status,
      userName,
    });
  };

  removePrivateLeague = async (pin: string): Promise<any> => {
    const url = this.urlBuilder([this.API_CONSTS.END_POINTS.PRIVATE_LEAGUE, pin]);
    return await axios.delete(url);
  };

  isPlayerNameExisting = async (userName: string): Promise<boolean> => {
    const url = this.urlBuilder(this.API_CONSTS.END_POINTS.EXISTS, { userName });
    return await axios.get(url);
  };

  getStandings = async (pin: string, timePeriod: TimePeriod, range: StandingsRange): Promise<boolean> => {
    const url = this.urlBuilder([this.API_CONSTS.END_POINTS.PRIVATE_LEAGUE, pin, 'standings', timePeriod, range]);
    return await axios.get(url);
  };

  createPlayer = async (userName: string, password: string, email: string): Promise<any> => {
    const url = this.urlBuilder(this.API_CONSTS.END_POINTS.CREATE_USER);
    const response = await axios.post(url, {
      userName,
      password,
      email,
    });

    return response;
  };

  sendRegistrationEmail = async (email: string): Promise<any> => {
    const url = this.urlBuilder(this.API_CONSTS.END_POINTS.SEND_REGISTRATION_EMAIL);
    const response = await axios.post(url, {
      email,
    });

    return response;
  };

  participateInALeague = async (pin: string): Promise<any> => {
    const url = this.urlBuilder([this.API_CONSTS.END_POINTS.PRIVATE_LEAGUE, pin, 'participate']);
    const response = await axios.post(url);

    return response;
  };

  confirmUser = async (userName: string, oobCode: string): Promise<any> => {
    const url = this.urlBuilder(this.API_CONSTS.END_POINTS.CONFIRM_USER, { userName, oobCode });
    return await axios.get(url);
  };

  getAd = async (redirectionUrl: string): Promise<any> => {
    const url = this.urlBuilder(this.API_CONSTS.END_POINTS.AD, { redirectionUrl });
    return await axios.get(url);
  };

  setAd = async (redirectionUrl: string): Promise<any> => {
    const url = this.urlBuilder(this.API_CONSTS.END_POINTS.AD);
    const response = await axios.post(url, {
      redirectionUrl,
    });

    return response;
  };

  setPrivateLeagueRegulations = async (pin: string, regulations: string): Promise<any> => {
    const url = this.urlBuilder([this.API_CONSTS.END_POINTS.PRIVATE_LEAGUE, pin, 'regulations']);
    const response = await axios.put(url, {
      regulations,
    });

    return response;
  };
}

export default new FirebaseService();
