import React, { useState, FC } from 'react';
import styled from 'styled-components';
import { Radio, Table } from 'antd';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons/lib';
import militaryGold from 'assets/militaryGold.svg';
import militaryBrown from 'assets/militaryBrown.svg';
import militarySilver from 'assets/militarySilver.svg';
import EmptySvg from 'assets/EmptySvg.svg';
import { InfoCircleTwoTone } from '@ant-design/icons/lib';
import { AdsComponent } from '../AdsComponent/AdsComponent';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { PRIVATE_LEAGUE_DETAILS, STANDINGS } from '../../helpers/RequestKeys';
import FirebaseService from '../../global/Firebase/FirebaseService';
import { useHistory, useParams } from 'react-router-dom';
import { StandingsRange, TimePeriod, Trend } from '../../beTypes';
import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';
import { CopyLinkParagraph } from '../CopyLinkParagraph/CopyLinkParagraph';
import { Select } from 'antd';
import { APP_ROUTES } from '../../global/AppRouter/routes';
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Option } = Select;

const MainComponent = styled.div`
  margin-top: 78px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextAndButtons = styled.div`
  width: 100%;
  padding: 0px 16px;
  ${props => props.theme.typography.overline}
  text-align:left;
  display: flex;
  flex-direction: column;
`;

const StyledText = styled.div`
  ${props => props.theme.typography.subtitle1};
  font-weight: bold;
`;

const PositionWithPoints = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 8px 20px;
`;

const CaretUpStyle = styled(CaretUpFilled)`
  color: ${props => props.theme.colors.main.primary};
  margin-right: 4px;
`;

const CaretDownStyle = styled(CaretDownFilled)`
  color: ${props => props.theme.colors.functional.danger};
  margin-right: 4px;
`;

const StylePlace = styled.div`
  margin-left: 8px;
`;

const PlaceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InviteBox = styled.div`
  display: flex;
  justify-content: center;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0 16px 16px 16px;
`;

const InviteText = styled.div`
  ${props => props.theme.typography.body2};
  margin-top: 8px;
`;

const InfoSignStyle = styled(InfoCircleTwoTone)`
  margin-right: 8px;
  margin-top: 12px;
`;

const EmptySvgStyle = styled.img`
  margin-top: 104px;
`;

const AdsComponentStyle = styled(AdsComponent)`
  margin: 0 20px 30px 20px;
`;

const TrendArrow: FC<{ trend?: Trend }> = ({ trend }) => {
  if (trend === 'same' || undefined) {
    return null;
  }

  if (trend === 'up') {
    return <CaretUpStyle />;
  } else {
    return <CaretDownStyle />;
  }
};

const StyleRadioGroup = styled(RadioGroup)`
  margin-top: 16px;
  width: 100%;
`;

const StyleTable = styled(Table)`
  width: 100%;
  cursor: pointer;
`;

const StyleRadioButton = styled(RadioButton)`
  margin-bottom: 28px;
  width: 33%;
  text-align: center;
`;

const StyleSelect = styled(Select)`
  .ant-select-selector {
    border-color: #1890ff !important;
  }
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
  color: #1890ff;
`;

export const TableMenu = () => {
  const { pin, name } = useParams();
  const [timePeriod, setTimePeriod] = useState<TimePeriod>('season');
  const [range, setRange] = useState<StandingsRange>('actual');
  const areAnyPlayers = true;
  const history = useHistory();

  const { data: standings, isLoading: standingLoad } = useQuery<any, any, any>(
    [STANDINGS, pin, timePeriod, range],
    () => FirebaseService.getStandings(pin, timePeriod, range)
  );

  const { data: privateLeague } = useQuery([PRIVATE_LEAGUE_DETAILS, pin], () =>
    FirebaseService.getPrivateLeagueDetails(pin)
  );

  if (!privateLeague) {
    return null;
  }

  const filterByStatus = _.filter(privateLeague.privateLeague.players, ['status', 'accepted']);

  const concatStandingsAndPrivateLeague = _.concat(standings?.standings || [], filterByStatus || []);

  const deleteDuplicate = _.uniqBy(concatStandingsAndPrivateLeague, 'userName');

  const withoutGamePoints = deleteDuplicate.map(el =>
    el.gamePoints === undefined
      ? el
      : {
          ...el,
          points: el.gamePoints,
        }
  );

  const columns = [
    {
      title: <StyledText>Place</StyledText>,
      dataIndex: 'place',
      width: '20%',
      render: (_place: number, _: any, baseIndex: number) => {
        const index = baseIndex + 1;
        if (index === 1) {
          return (
            <PlaceBox>
              <img src={militaryGold} alt="military gold" />
              <StylePlace>{index}</StylePlace>
            </PlaceBox>
          );
        } else if (index === 2) {
          return (
            <PlaceBox>
              <img src={militarySilver} alt="military silver" />
              <StylePlace>{index}</StylePlace>
            </PlaceBox>
          );
        } else if (index === 3) {
          return (
            <PlaceBox>
              <img src={militaryBrown} alt="military brown" />
              <StylePlace>{index}</StylePlace>
            </PlaceBox>
          );
        } else {
          return (
            <PlaceBox>
              <StylePlace>{index}</StylePlace>
            </PlaceBox>
          );
        }
      },
    },
    {
      title: <StyledText>Name</StyledText>,
      dataIndex: `userName`,
    },
    {
      title: <StyledText>Points</StyledText>,
      width: '20%',
      dataIndex: 'points',
      render: (points: number, value: any) => (
        <PositionWithPoints>
          {value?.trend ? <TrendArrow trend={value.trend} /> : null}
          {points || 0}
        </PositionWithPoints>
      ),
    },
  ];

  if (!areAnyPlayers) {
    return (
      <MainComponent>
        <AdsComponentStyle>320 x 50 AD</AdsComponentStyle>
        {!standings && standingLoad ? (
          <CenteredSpinner />
        ) : (
          <>
            <InviteBox>
              <InfoSignStyle translate="yes" />
              <InviteText>
                <CopyLinkParagraph pin={pin}>
                  You can invite players by sharing league PIN number <b>{pin}</b>{' '}
                </CopyLinkParagraph>
              </InviteText>
            </InviteBox>
            <EmptySvgStyle src={EmptySvg} alt="emptySvg" />
            <b>No players play</b>
          </>
        )}
      </MainComponent>
    );
  }

  return (
    <MainComponent>
      <AdsComponentStyle />
      <TextAndButtons>
        MANAGER OF THE
        <StyleRadioGroup
          defaultValue={timePeriod}
          onChange={e => {
            setTimePeriod(e.target.value);
          }}
        >
          <StyleRadioButton
            value="season"
            onChange={() => {
              setRange('actual');
            }}
          >
            Season
          </StyleRadioButton>
          <StyleRadioButton value="monthly">Month</StyleRadioButton>
          <StyleRadioButton value="weekly">Week</StyleRadioButton>
        </StyleRadioGroup>
        {timePeriod === 'season' ? (
          <StyleSelect defaultValue="actual" value={range} disabled>
            <Option style={{ textAlign: 'center' }} value="actual">
              CURRENT
            </Option>
          </StyleSelect>
        ) : (
          <StyleSelect defaultValue="actual" value={range} onChange={value => setRange(value as StandingsRange)}>
            <Option style={{ textAlign: 'center' }} value="actual">
              CURRENT
            </Option>
            <Option style={{ textAlign: 'center' }} value="previous">
              PREVIOUS
            </Option>
          </StyleSelect>
        )}
      </TextAndButtons>
      {!standings && standingLoad ? (
        <CenteredSpinner />
      ) : (
        <>
          <StyleTable
            columns={columns}
            dataSource={_.orderBy(withoutGamePoints, ['points'], ['desc', 'asc'])}
            pagination={false}
            onRow={(userName: any) => {
              return {
                onClick: () => {
                  history.push(APP_ROUTES.LEAGUE_PREVIEW_GAME(name, pin, userName.userName));
                },
              };
            }}
          />
        </>
      )}
    </MainComponent>
  );
};
