import React, { FunctionComponent } from 'react';
import useLocalStorage from 'react-use-localstorage';
import { Modal } from 'antd';
import styled from 'styled-components';
import { privacyPolicyConfig, TOSConfig } from '../CreateAccount/CreateAccount';

const CookieText = styled.p`
  ${props => props.theme.typography.caption};
  color: ${props => props.theme.colors.utils.text.dark};
`;

const TextHere = styled.span`
  ${props => props.theme.typography.caption};
  color: ${props => props.theme.colors.main.secondary};
  text-decoration-line: ${props => props.theme.textDecorationLine};
  text-decoration-skip: spaces;
`;

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-btn-primary {
    background-color: ${props => props.theme.colors.main.primary};
    border: 1px solid ${props => props.theme.colors.main.primary};
  }
`;

export const CookieBar: FunctionComponent = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [isVisible, setIsVisible] = useLocalStorage('isVisible', 'true');

  const handleOk = () => {
    setIsVisible('false');
  };

  const handleCancel = () => {
    setIsVisible('false');
  };

  if (isVisible === 'false') {
    return null;
  }

  return (
    <StyledModal
      title="Welcome in Predict The Leagues"
      visible={Boolean(isVisible)}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Accept"
    >
      {contextHolder}
      <CookieText>
        By using this website you accept our
        <span
          onClick={() => {
            modal.confirm(TOSConfig);
          }}
        >
          <TextHere> term of service</TextHere>.
        </span>
        This website uses cookies. By using this website you agree with our
        <span
          onClick={() => {
            modal.confirm(privacyPolicyConfig);
          }}
        >
          <TextHere>privacy policy.</TextHere>
        </span>
      </CookieText>
    </StyledModal>
  );
};
