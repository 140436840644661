import React, { FC } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import { vh, vw } from 'helpers/styled';

const Container = styled.div<{ $background: boolean }>`
  height: ${vh(100)};
  width: ${vw(100)};
  background-color: ${props => (props.$background ? 'rgba(0, 0, 0, 0.7)' : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  top: 0;
  position: fixed;
`;

const SpinnerWithOverlay: FC<SpinProps & { background?: boolean; containerProps?: any }> = ({
  background = true,
  containerProps,
  ...spinnerProps
}) => (
  <Container $background={background} {...containerProps}>
    <Spin {...spinnerProps} size="large" />
  </Container>
);

export default SpinnerWithOverlay;
