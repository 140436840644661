import React from 'react';
// @ts-ignore
import { ReactQueryDevtools } from 'react-query-devtools';

/*
import { CenteredSpinner } from '../../components/CenteredSpinner/CenteredSpinner';
import { useIsFetching } from 'react-query';

// @ts-ignore
function GlobalLoadingIndicator() {
  const isFetching = useIsFetching();

  return isFetching ? <CenteredSpinner /> : null;
}
*/

export const ReactQuery = () => <ReactQueryDevtools initialIsOpen />;
