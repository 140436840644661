import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { notification } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { InferType, object, string } from 'yup';
import { UserOutlined } from '@ant-design/icons';
import { LoginButton } from 'components/Button/LoginButton';
import { UnlockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { TopNav } from 'components/TopNav/TopNav';
import { Card } from 'components/Card/Card';
import { APP_ROUTES } from 'global/AppRouter/routes';
import { useFirebase } from 'global/Firebase';
import FirebaseService from 'global/Firebase/FirebaseService';
import { getUrlsParams } from 'helpers/getUrlsParams';
import { ActionType } from '../../pages/Action/Action';
import { FormikInput } from 'components/FormikFields/FormikInput/FormikInput';
import { TopNavWithLogo } from '../TopNavWithLogo/TopNavWithLogo';
import SpinnerWithOverlay from 'components/SpinnerWithOverlay/SpinnerWithOverlay';
import { HOW_TO_PLAY_URL } from 'helpers/consts';

export type LoginType = {
  redirectionPath?: string;
  topNavName?: any;
};

const Caption = styled.span(props => props.theme.typography.caption);

const MainComponent = styled.div`
  padding: 84px 16px 0px 16px;
`;

const GreyCard = styled(Card)`
  padding: 24px;
`;

const InputsComponent = styled.div`
  margin-top: 12px;
  flex-direction: column;
`;

const FirstInputMargin = styled.div`
  margin-bottom: 24px;
`;

const UsernameInput = styled(FormikInput)`
  border-radius: 2px;
`;

const UserOutlinedStyle = styled(UserOutlined)`
  && {
    color: ${props => props.theme.colors.main.secondary};
  }
`;

const ResetPassword = styled(Caption)`
  margin-top: 24px;
  margin-bottom: 28px;
  align-self: flex-end;
`;

const RegisterCaption = styled(Caption)`
  text-align: center;
  margin-top: 24px;
`;

const UnlockOutlinedStyle = styled(UnlockOutlined)`
  && {
    color: ${props => props.theme.colors.main.secondary};
  }
`;

const PasswordInput = styled(FormikInput)`
  border-radius: 2px;
`;

const LinkStyle = styled(Link)`
  margin-right: 2px;
  color: ${props => props.theme.colors.main.secondary};
  text-decoration: none;
`;

const StyledErrorMessage = styled.div`
  color: ${props => props.theme.colors.functional.error};
`;

const ResendEmail = styled.div`
  margin-right: 2px;
  color: ${props => props.theme.colors.main.secondary};
  text-decoration: none;
`;

const validationSchema = object({
  email: string()
    .email()
    .required(),
  password: string().required(),
}).required();

type FormValues = InferType<typeof validationSchema>;

const initialValues: FormValues = {
  email: '',
  password: '',
};

const StyledALink = styled.a`
  display: block;
`;

export const LoginComponent: FC<LoginType> = ({ redirectionPath, topNavName }) => {
  const [apiError, setApiError] = useState('');
  const [isMessageShown, setIsMessageShown] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailName, setEmailName] = useState('');
  const history = useHistory();
  const firebase = useFirebase();
  const { actionCode, action, userName } = getUrlsParams(['actionCode', 'action', 'userName']);
  const { state } = useLocation<{ passwordReset?: boolean }>();
  const location = useLocation<{ referer: string }>();
  useEffect(() => {
    if (state?.passwordReset) {
      notification.success({
        message: 'Password changed!',
        description: 'You can now login in using new password',
      });
    }
  }, [state]);

  if (!firebase) {
    return null;
  }

  if ((action as ActionType) === 'verify' && !verified) {
    firebase
      .applyActionCode(actionCode)
      .then(async () => {
        setVerified(true);
        await FirebaseService.confirmUser(userName, actionCode);
        notification.success({
          duration: 3,
          message: 'Your email has been confirmed.',
          description: 'You can now log in',
        });
      })
      .catch(() => {
        notification.error({
          duration: 3,
          message: "Your email can't be confirmed.",
          description: 'Please check verification link once more or contact to our team',
        });
      });
  }

  if ((action as ActionType) === 'verificationEmailSent' && !isMessageShown) {
    notification.info({
      duration: 4.5,
      message: 'We send you an email to verify your account.',
      description: 'Please check your inbox or junk mail and click in the verification link.',
    });
    setIsMessageShown(true);
  }

  const LoginOrAnotherLinkRedirection = () => {
    if (location.state === undefined || null) {
      return history.push(`${redirectionPath}`);
    } else {
      return history.push(`${location.state.referer}`);
    }
  };

  const sendVerificationEmail = () => {
    return (
      <ResendEmail
        style={{ cursor: 'pointer' }}
        onClick={async () => {
          setIsLoading(true);
          await FirebaseService.sendRegistrationEmail(emailName);
          notification.success({
            duration: 1.5,
            message: 'Email was sent.',
            description: 'Please check your inbox or junk mail.',
          });
          setIsLoading(false);
        }}
      >
        Click here and resend email
      </ResendEmail>
    );
  };

  const handleLogin = async ({ email, password }: FormValues): Promise<void> => {
    try {
      setIsLoading(true);
      await firebase.auth.signInWithEmailAndPassword(email, password);
      const idToken = await firebase?.getCurrentUser()?.getIdToken();
      setEmailName(email);
      if (idToken) {
        localStorage.setItem('idToken', idToken || '');
        await FirebaseService.login();
        LoginOrAnotherLinkRedirection();
      }
    } catch (error) {
      setIsLoading(false);
      setApiError(error.message);
    }
  };

  return (
    <>
      {isLoading && <SpinnerWithOverlay />}
      {topNavName ? <TopNav>{topNavName}</TopNav> : <TopNavWithLogo />}
      <Formik initialValues={initialValues} onSubmit={handleLogin} validationSchema={validationSchema}>
        {() => (
          <Form>
            <MainComponent>
              <GreyCard>
                <h5>Log in to your account</h5>
                <InputsComponent>
                  <FirstInputMargin>
                    <UsernameInput name="email" placeholder="Email" prefix={<UserOutlinedStyle translate="yes" />} />
                  </FirstInputMargin>
                  <PasswordInput
                    name="password"
                    type="password"
                    placeholder="Password"
                    prefix={<UnlockOutlinedStyle translate="yes" />}
                  />
                </InputsComponent>
                <ResetPassword>
                  <LinkStyle to={APP_ROUTES.FORGOT_PASSWORD}>Forgot password?</LinkStyle>
                </ResetPassword>
                <LoginButton TextInButton="Log in" htmlType="submit" />
                <RegisterCaption>
                  New to Predict The Leagues? <LinkStyle to={APP_ROUTES.CREATE_ACCOUNT}>Register now.</LinkStyle>
                  <StyledALink href={HOW_TO_PLAY_URL} rel="noreferrer" target="_blank">
                    How to play?
                  </StyledALink>
                </RegisterCaption>
              </GreyCard>
              <StyledErrorMessage>
                {apiError === 'Unauthorized' ? `You need to confirm your email first to login.` : apiError}{' '}
                {apiError === 'Unauthorized' ? sendVerificationEmail() : null}
              </StyledErrorMessage>
            </MainComponent>
          </Form>
        )}
      </Formik>
    </>
  );
};
