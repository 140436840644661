import originalAxios from 'axios';

const axios = originalAxios.create({
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

axios.interceptors.request.use(config => {
  config.headers.authorization = `Bearer ${localStorage.getItem('idToken')}`;
  return config;
});

axios.interceptors.response.use(
  response => response.data,
  error => {
    const { response } = error;

    if (response && response.data) {
      return Promise.reject({ message: response.data.message });
    }

    return Promise.reject(error.message || error);
  }
);

export default axios;
