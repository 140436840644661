import React, { FC } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { LoginButton } from 'components/Button/LoginButton';
import { UnlockOutlined } from '@ant-design/icons';
import { vh } from 'helpers/styled';
import { Card } from 'components/Card/Card';
import { InferType, object, ref, string } from 'yup';
import { useFirebase } from 'global/Firebase';
import { getUrlsParams } from 'helpers/getUrlsParams';
import { notification } from 'antd';
import { FormikInput } from 'components/FormikFields/FormikInput/FormikInput';
import { TopNavWithLogo } from '../../components/TopNavWithLogo/TopNavWithLogo';
import firebaseLib from 'firebase';
import { queryCache } from 'react-query';
import { APP_ROUTES } from '../../global/AppRouter/routes';

const MainComponent = styled.div`
  min-height: calc(${vh(100)} - 56px);
  padding: 128px 16px 0px 16px;
`;

const GreyCard = styled(Card)`
  padding: 24px;
`;

const InputsComponent = styled.div`
  margin-top: 24px;
  flex-direction: column;
`;

const UnlockOutlinedStyle = styled(UnlockOutlined)`
  && {
    color: #1890ff;
  }
`;

const PasswordBorder = styled(FormikInput)`
  border-radius: 2px;
  margin-bottom: 24px;
`;

const validationSchema = object({
  password: string().required(),
  repeatPassword: string()
    .oneOf([ref('password')], "Passwords doesn't match")
    .required(),
}).required();

type FormValues = InferType<typeof validationSchema>;

const initialValues: FormValues = {
  password: '',
  repeatPassword: '',
};

export const NewPassword: FC = () => {
  const firebase = useFirebase();
  const history = useHistory();

  const handleSubmit = async ({ password }: FormValues): Promise<void> => {
    const { oobCode, continueUrl } = getUrlsParams(['oobCode', 'continueUrl']);
    try {
      const redirectUrl = await firebase.handleResetPassword(password, oobCode, continueUrl);
      firebaseLib
        .auth()
        .signOut()
        .then(function() {
          queryCache.clear();
          history.push(APP_ROUTES.LOGIN);
        })
        .catch(function(error) {
          console.log(error);
        });
      history.push(redirectUrl, { passwordReset: true });
    } catch (e) {
      notification.error({
        message: 'Unexpected error',
        description: 'Please, try again',
      });
    }
  };

  return (
    <>
      <TopNavWithLogo />
      <MainComponent>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {() => (
            <Form>
              <GreyCard>
                <h5>New Password</h5>
                <InputsComponent>
                  <div>
                    <PasswordBorder
                      size="large"
                      name="password"
                      placeholder="Password"
                      type="password"
                      prefix={<UnlockOutlinedStyle translate="yes" />}
                    />
                  </div>
                  <PasswordBorder
                    size="large"
                    name="repeatPassword"
                    placeholder="Repeat Password"
                    type="password"
                    prefix={<UnlockOutlinedStyle translate="yes" />}
                  />
                </InputsComponent>
                <LoginButton TextInButton="Change" htmlType="submit" />
              </GreyCard>
            </Form>
          )}
        </Formik>
      </MainComponent>
    </>
  );
};
