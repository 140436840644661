import React, { FC } from 'react';
import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { InputProps, TextAreaProps } from 'antd/lib/input';

const StyledInput = styled(AntInput)`
  && {
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

const PasswordInput = styled(AntInput.Password)`
  && {
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

const StyledTextArea = styled(AntInput.TextArea)`
  && {
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

type CommonProps = {
  hasError?: boolean;
  visibilityToggle?: boolean;
  type?: string;
};

export type Props = InputProps & CommonProps;

export type StyledTextAreaProps = TextAreaProps & CommonProps;

export const Input: FC<Props | StyledTextAreaProps> = props => {
  switch (props.type) {
    case 'password':
      return <PasswordInput {...(props as Props)} />;
    case 'textarea':
      return <StyledTextArea {...(props as StyledTextAreaProps)} />;
    default:
      return <StyledInput {...(props as Props)} />;
  }
};
