import React, { FC } from 'react';
import styled from 'styled-components';
import { InfoCircleTwoTone } from '@ant-design/icons/lib';
import { APP_ROUTES } from '../../global/AppRouter/routes';
import { Card } from '../Card/Card';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LoginButton } from '../Button/LoginButton';
import { queryCache, useMutation, useQuery } from 'react-query';
import { PRIVATE_LEAGUE, PRIVATE_LEAGUES } from '../../helpers/RequestKeys';
import FirebaseService from '../../global/Firebase/FirebaseService';
import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';
import { GameLeague, Player } from '../../beTypes';
import AlreadyBetCheckSVG from 'assets/already-bet-check.svg';
import { AdsComponent } from '../AdsComponent/AdsComponent';
import { Icon } from 'components/Icon';
import Dollar from 'assets/Dollar.svg';
import SpinnerWithOverlay from '../SpinnerWithOverlay/SpinnerWithOverlay';
import { Regulations } from './Regulations';
import { Alert } from 'antd';
import { isUserPlayerBanned, isUserPlayerWaiting } from '../../helpers/player';
import { getUserName } from '../../helpers/token';
import { TopNav } from '../TopNav/TopNav';
import { CopyLinkParagraph } from '../CopyLinkParagraph/CopyLinkParagraph';
import { TeamLogo } from '../TeamLogo/TeamLogo';
import { useRWD, media } from '../../global/RWD';

const MainComponent = styled.div`
  padding: 90px 16px 40px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InviteBox = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0 16px 16px 16px;
`;

const InviteText = styled.div`
  ${props => props.theme.typography.body2};
  margin-top: 8px;
`;

const InfoSignStyle = styled(InfoCircleTwoTone)`
  margin-right: 8px;
  margin-top: 12px;
`;

const EditText = styled.div`
  width: 100%;
  ${props => props.theme.typography.overline};
  margin: 18px 0;
`;

const GreyCard = styled(Card)`
  padding: 24px;
  width: 100%;
  display: flex;
  ${media.xs`
    flex-direction: column;
  `}
  ${media.lg`
    flex-direction: row;

  `}
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const TextInGreyCard = styled.h6`
  font-weight: bold;
  margin-bottom: 0;
  text-align: left;
`;

const StyleLink = styled(Link)`
  text-decoration: none;
  text-align: center;
`;

const ButtonRight = styled(LoginButton)`
  background-color: ${props => props.theme.colors.main.primary};
  color: ${props => props.theme.colors.utils.text.light};
  border-radius: 2px;
  float: right;
`;

const BottomButton = styled(LoginButton)`
  &&& {
    background-color: ${props => props.theme.colors.main.primary};
    color: ${props => props.theme.colors.utils.text.light};
    border-radius: 2px;
    width: 100%;
    position: sticky;
    bottom: 0;
  }
`;

const BottomButtonDisabled = styled(LoginButton)`
  &&& {
    background-color: ${props => props.theme.colors.functional.disabled};
    color: ${props => props.theme.colors.utils.text.light};
    border-radius: 2px;
    width: 100%;
    position: sticky;
    bottom: 0;
  }
`;

type ParticipateButtonPropTypes = {
  areAllGamesPlayed: boolean;
  participateInALeague: () => void;
};

const ParticipateButton: FC<ParticipateButtonPropTypes> = ({ areAllGamesPlayed, participateInALeague }) => {
  if (!areAllGamesPlayed) {
    return <BottomButtonDisabled TextInButton="Participate" />;
  } else {
    return <BottomButton TextInButton="Participate" onClick={participateInALeague} />;
  }
};

const LeagueNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;

  ${media.xs`
    align-items: center;
    margin: 32px;
  `}
  ${media.lg`
   margin-bottom: 0;
    align-items: flex-start;
  `}
`;

const StyledIcon = styled(Icon)`
  background-color: ${props => props.theme.colors.main.primary};
  min-width: 42px;
`;

const CreditsText = styled.p`
  ${props => props.theme.typography.body2}
`;

const StyledImg = styled.img`
  margin-left: 4px;
  margin-bottom: 4px;
`;

const AdsComponentStyle = styled(AdsComponent)`
  margin: 0 0 30px 0;
`;

type BetInLeagueTableType = {
  privateLeague: any;
  pin: string;
  name: string;
};
const ChosenTeam = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
`;

const BetInLeagueTable: FC<BetInLeagueTableType> = ({ privateLeague, pin, name }) => {
  const { less, more } = useRWD();
  const userPlayer = privateLeague.players.find((player: Player) => player.userName === getUserName());

  if (privateLeague.isUserAnOwner && isUserPlayerWaiting(privateLeague)) {
    return <Alert message="You need first to be accepted to participate in a game." type="warning" showIcon />;
  }

  if (privateLeague.isUserAnOwner && !userPlayer) {
    return (
      <Alert
        message={<CopyLinkParagraph pin={pin}>Use your {pin} - pin to join the league</CopyLinkParagraph>}
        type="warning"
        showIcon
      />
    );
  }

  if (isUserPlayerBanned(privateLeague)) {
    return <Alert message="You are banned in this league." type="warning" showIcon />;
  }

  return (
    <>
      {privateLeague.gameLeagues.map((gameLeague: GameLeague) => (
        <GreyCard key={gameLeague.id}>
          {less.sm && <TeamLogo logoUrl={gameLeague.logo} style={{ width: '100px', marginRight: '16px' }} />}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {more.sm && <TeamLogo logoUrl={gameLeague.logo} style={{ width: '100px', marginRight: '16px' }} />}
            <LeagueNameWrapper>
              <TextInGreyCard>{gameLeague.name}</TextInGreyCard>
              <p>{gameLeague.country}</p>
              <CreditsText>
                Credits: {gameLeague.playerCredits}/{gameLeague.maxCredits}
                <StyledImg src={Dollar} alt="Dollar sign" />
              </CreditsText>
              <div> Chosen teams: </div>
              <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'left' }}>
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                  {gameLeague.teams
                    .filter(team => team.isUserBet)
                    .map((team, index) => (
                      <div style={{ display: 'flex', flexDirection: 'row' }} key={team.id}>
                        <ChosenTeam key={team.id} style={{ height: 25 }}>
                          {index + 1}. {team.name}
                        </ChosenTeam>
                      </div>
                    ))}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', marginLeft: 4 }}>
                  {gameLeague.teams
                    .filter(team => team.isUserBet)
                    .map(team => (
                      <div style={{ display: 'flex', flexDirection: 'row' }} key={team.id}>
                        <div>
                          <StyledImg src={Dollar} alt="Dollar sign" /> {team.credits}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </LeagueNameWrapper>
          </div>
          {gameLeague.isUserAlreadyBet ? (
            <StyledIcon svgLink={AlreadyBetCheckSVG} width={'42px'} height={'42px'} />
          ) : (
            <StyleLink
              to={APP_ROUTES.LEAGUE_PIN_PLAY(name, pin, `${gameLeague.name} (${gameLeague.country})`, gameLeague.id)}
            >
              <ButtonRight TextInButton="Play" />
            </StyleLink>
          )}
        </GreyCard>
      ))}
    </>
  );
};

export const GameMenuPreview = () => {
  const { pin, name, userName } = useParams();
  const history = useHistory();
  const { data: data, isLoading: isLoading } = useQuery<any, any, any>([PRIVATE_LEAGUE, pin], () =>
    FirebaseService.getPrivateLeagueDetails(pin)
  );

  const { data: dataPreview, isLoading: isLoadingPreview } = useQuery<any, any, any>(
    [PRIVATE_LEAGUE, pin, userName],
    () => FirebaseService.getPrivateLeagueDetailsByUserName(pin, userName)
  );

  const [participateInALeague, { isLoading: isParticipateLoading }] = useMutation(
    () => FirebaseService.participateInALeague(pin),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(PRIVATE_LEAGUES);
        queryCache.invalidateQueries([PRIVATE_LEAGUE, pin]);

        history.replace(APP_ROUTES.LEAGUE(name, pin, 'TABLE'));
      },
    }
  );

  const areAllGamesPlayed =
    data && !data.privateLeague.gameLeagues.find((league: GameLeague) => !league.isUserAlreadyBet);
  const areAllGamesPlayedPreview =
    dataPreview && !dataPreview.privateLeague.gameLeagues.find((league: GameLeague) => !league.isUserAlreadyBet);

  return (
    <>
      {userName ? <TopNav isWithBackArrow>{userName}</TopNav> : null}
      <MainComponent>
        {isParticipateLoading && (
          <SpinnerWithOverlay
            containerProps={{
              style: {
                top: '0',
                position: 'fixed',
              },
            }}
          />
        )}
        <AdsComponentStyle />
        <InviteBox>
          <InfoSignStyle translate="yes" />
          <InviteText>You need to play in every league to take a part in {name} competition.</InviteText>
        </InviteBox>
        <EditText>BET IN EVERY LEAGUE</EditText>
        {!userName ? (
          !data && isLoading ? (
            <CenteredSpinner />
          ) : (
            <>
              <BetInLeagueTable privateLeague={data.privateLeague} pin={pin} name={name} />
              <Regulations
                isUserAnOwner={data.privateLeague.isUserAnOwner}
                retrievedRegulations={data.privateLeague.regulations}
              />
            </>
          )
        ) : !dataPreview && isLoadingPreview ? (
          <CenteredSpinner />
        ) : (
          <>
            <BetInLeagueTable privateLeague={dataPreview.privateLeague} pin={pin} name={name} />
            <Regulations
              isUserAnOwner={dataPreview.privateLeague.isUserAnOwner}
              retrievedRegulations={dataPreview.privateLeague.regulations}
            />
          </>
        )}
      </MainComponent>
      {!userName
        ? data &&
          !data.privateLeague.isUserPlaying && (
            <ParticipateButton
              areAllGamesPlayed={areAllGamesPlayed}
              participateInALeague={() => participateInALeague(pin)}
            />
          )
        : dataPreview &&
          !dataPreview.privateLeague.isUserPlaying && (
            <ParticipateButton
              areAllGamesPlayed={areAllGamesPlayedPreview}
              participateInALeague={() => participateInALeague(pin)}
            />
          )}
    </>
  );
};
