import React, { ComponentType } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { APP_ROUTES } from 'global/AppRouter/routes';
import { isEmailVerified, isUserLogIn } from '../../helpers/token';

type Props = {
  component: ComponentType;
} & RouteProps;

export const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!isUserLogIn() || !isEmailVerified()) {
          return (
            <Redirect
              to={{
                pathname: APP_ROUTES.LOGIN,
                state: { referer: props.location.pathname },
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};
export default PrivateRoute;
