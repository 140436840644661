import React, { FC } from 'react';
import { useParams } from 'react-router';
import FirebaseService from '../../global/Firebase/FirebaseService';
import { PRIVATE_LEAGUES } from '../../helpers/RequestKeys';
import { queryCache, useMutation } from 'react-query';
import { DrawerLeague } from './Drawer';
import { useLocation } from 'react-router-dom';

export const DrawerLeagueFromLink: FC = () => {
  const { pin } = useParams();
  const location = useLocation();

  const [joinPrivateLeague] = useMutation((pin: string) => FirebaseService.joinPrivateLeague(pin), {
    onSuccess: () => {
      queryCache.invalidateQueries([PRIVATE_LEAGUES]);
    },
  });

  return (
    <>
      {location.pathname === `/home/join-league/${pin}` ? (
        <DrawerLeague PIN={pin} isOpened={true} onAccept={(pin: string) => joinPrivateLeague(pin)} />
      ) : (
        <DrawerLeague isOpened={false} onAccept={pin => joinPrivateLeague(pin)} />
      )}
    </>
  );
};
