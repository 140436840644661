import React, { FC } from 'react';
import styled from 'styled-components';
import { InfoCircleTwoTone } from '@ant-design/icons/lib';
import { Empty } from 'antd';
import { AdsComponent } from '../AdsComponent/AdsComponent';
import { Player } from '../../beTypes';
import { useQuery } from 'react-query';
import FirebaseService from 'global/Firebase/FirebaseService';
import { PRIVATE_LEAGUE_DETAILS } from 'helpers/RequestKeys';
import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';
import PlayerBox from './PlayerBox/PlayerBox';
import { CopyLinkParagraph } from '../CopyLinkParagraph/CopyLinkParagraph';

const MainComponent = styled.div`
  padding: 90px 16px 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const InviteBox = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0 16px 16px 16px;
`;

const InviteText = styled.div`
  ${props => props.theme.typography.body2};
  margin-top: 8px;
`;

const InfoSignStyle = styled(InfoCircleTwoTone)`
  margin-right: 8px;
  margin-top: 12px;
`;

const AdsComponentStyle = styled(AdsComponent)`
  margin: 0 0 30px 0;
`;

type Props = {
  pin: string;
};

const EmptyWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
`;

export const PlayerMenu: FC<Props> = ({ pin }) => {
  const { data, isLoading } = useQuery([PRIVATE_LEAGUE_DETAILS, pin], () =>
    FirebaseService.getPrivateLeagueDetails(pin)
  );

  const players: Player[] = data?.privateLeague?.players || [];
  return (
    <>
      <MainComponent>
        <AdsComponentStyle />
        <InviteBox>
          <InfoSignStyle translate="yes" />
          <InviteText>
            <CopyLinkParagraph pin={pin}>
              You can invite players by sharing league PIN number <b>{pin}</b>{' '}
            </CopyLinkParagraph>
          </InviteText>
        </InviteBox>
        <EmptyWrapper>
          {isLoading ? (
            <CenteredSpinner />
          ) : players.length ? (
            players.map(player => <PlayerBox player={player} pin={pin} key={player.userName} />)
          ) : (
            <Empty />
          )}
        </EmptyWrapper>
      </MainComponent>
    </>
  );
};
