import React, { useState } from 'react';
import { TopNav } from 'components/TopNav/TopNav';
import styled from 'styled-components';
import { Button, Input, message } from 'antd';
import { CreateLeagueTable } from './CreateLeagueTable/CreateLeagueTable';
import { vh } from '../../helpers/styled';
import { queryCache, useMutation } from 'react-query';
import FirebaseService from '../../global/Firebase/FirebaseService';
import { PRIVATE_LEAGUES } from '../../helpers/RequestKeys';
import { useHistory } from 'react-router-dom';
import SpinnerWithOverlay from '../../components/SpinnerWithOverlay/SpinnerWithOverlay';
import { APP_ROUTES } from '../../global/AppRouter/routes';

type InputType = {
  inputErrorStyle?: boolean;
};

const MainComponent = styled.div`
  min-height: calc(${vh(100)} - 96px);
  width: 100%;
`;

const InputComponent = styled.div`
  padding: 40px 52px;
  width: auto;
`;

const InputStyle = styled(Input)<InputType>`
  border-radius: 2px;
  border-color: ${props => props.inputErrorStyle && props.theme.colors.functional.error};
  :hover {
    border-color: ${props => props.inputErrorStyle && props.theme.colors.functional.error};
  }
`;

const InputErrorStyle = styled.div`
  font-size: 12px;
  padding-left: 5px;
  color: ${props => props.theme.colors.functional.error};
`;

const ChooseLeagueText = styled.div`
  ${props => props.theme.typography.overline};
  margin: 0 0 14px 16px;
`;

const ButtonStyle = styled(Button)`
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 1000;
  background-color: ${props => props.theme.colors.main.primary};
  ${props => props.theme.typography.body1};
  color: ${props => props.theme.colors.utils.text.light};
  border: 0;
`;

const CreateLeagueTableStyle = styled(CreateLeagueTable)`
  && {
    .ant-table-pagination.ant-pagination {
      display: none;
    }
  }
`;

type CreatePrivateLeagueData = {
  name: string;
  chosenRealLeagueIds: number[];
  regulations: string;
};

const RegulationsWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
`;

const StyledTextArea = styled(Input.TextArea)`
  margin: 0 52px;
  && {
    max-width: calc(100% - 108px);
  }
`;

export const CreateLeague = () => {
  const [leagueName, setLeagueName] = useState('');
  const [regulations, setRegulations] = useState('');
  const [chosenRealLeagueIds, setChosenRealLeagueIds] = useState<number[]>([]);
  const [inputError, setInputError] = useState({
    notAllowed: {
      character: false,
    },
  });

  const history = useHistory();

  const [createPrivateLeague, { isLoading }] = useMutation(
    (createPrivateLeagueData: CreatePrivateLeagueData) =>
      FirebaseService.createPrivateLeague(
        createPrivateLeagueData.name,
        createPrivateLeagueData.chosenRealLeagueIds,
        createPrivateLeagueData.regulations
      ),
    {
      onSuccess: () => {
        queryCache.invalidateQueries([PRIVATE_LEAGUES]);
      },
    }
  );

  return (
    <>
      <TopNav isWithBackArrow={true}>Create League</TopNav>
      {isLoading && <SpinnerWithOverlay />}
      <MainComponent>
        <InputComponent>
          <InputStyle
            placeholder="League Name"
            value={leagueName}
            onChange={e => {
              const reg = /^\w+$/;
              if (!reg.test(e.target.value)) {
                setInputError({
                  notAllowed: {
                    character: true,
                  },
                });
              } else
                setInputError({
                  notAllowed: {
                    character: false,
                  },
                });
              setLeagueName(e.target.value);
            }}
            required={true}
            inputErrorStyle={inputError.notAllowed.character}
          />
          {inputError.notAllowed.character && (
            <InputErrorStyle>League name can be empty and use spacial characters.</InputErrorStyle>
          )}
        </InputComponent>
        <div>
          <ChooseLeagueText>CHOOSE AT LEAST 4 LEAGUES</ChooseLeagueText>
          <CreateLeagueTableStyle setChosenRealLeagueIds={setChosenRealLeagueIds} />
        </div>
        <RegulationsWrapper>
          <ChooseLeagueText>ADD LEAGUE REGULATIONS</ChooseLeagueText>
          <StyledTextArea
            autoSize={{ minRows: 4 }}
            onChange={e => setRegulations(e.target.value)}
            value={regulations}
          />
        </RegulationsWrapper>
      </MainComponent>
      <ButtonStyle
        size="large"
        onClick={async () => {
          if (!leagueName) {
            return message.error(`Name your league`);
          } else if (chosenRealLeagueIds.length < 4) return message.error(`You need to choose at least 4 leagues`);
          else {
            const resp = await createPrivateLeague({ name: leagueName, chosenRealLeagueIds, regulations });
            history.push(APP_ROUTES.LEAGUE(resp.privateLeague.name, resp.privateLeague.pin));
          }
        }}
      >
        Create
      </ButtonStyle>
    </>
  );
};
