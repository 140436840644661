import React, { FC, useState } from 'react';
import { Button, Drawer, InputNumber } from 'antd';
import PencilSvg from '../../assets/PencilSvg.svg';
import { Card } from '../Card/Card';
import styled from 'styled-components';

export type DrawerBottomType = {
  credits: number;
  name: string;
  onAccept: (arg?: number) => any;
};

const CardGrey = styled(Card)`
  text-align: left;
  padding: 24px 52px 24px 24px;
  ${props => props.theme.typography.body2};
  margin-bottom: 10px;
`;

const InputStyle = styled(InputNumber)`
  margin: 24px 0 0 30px;
  width: auto;
`;

const ButtonChangeStyle = styled(Button)`
  background-color: ${props => props.theme.colors.main.primary};
  color: ${props => props.theme.colors.utils.text.light};
  margin-left: 8px;
  margin-right: 16px;
`;

const ButtonsBox = styled.div`
  display: flex;
  float: right;
  margin-bottom: 10px;
`;

const DrawerStyle = styled(Drawer)`
  && {
    .ant-drawer-content-wrapper {
      height: auto !important;
    }
    .ant-drawer-body {
      padding: 0 0 10px 0px;
    }
    .ant-drawer-header {
      border: none;
    }
    .ant-drawer-title {
      font-weight: bold;
    }
  }
`;

export const DrawerBottom: FC<DrawerBottomType> = ({ name, credits, onAccept }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [inputValue, setInputValue] = useState(credits);

  return (
    <>
      <img src={PencilSvg} alt="pencil svg" onClick={() => setIsMenuOpened(true)} />
      <DrawerStyle
        title={name}
        placement="bottom"
        closable={false}
        onClose={() => {
          setIsMenuOpened(false);
        }}
        visible={isMenuOpened}
      >
        <CardGrey>
          Set new credit amount for {name}
          <InputStyle placeholder={`${credits}`} onChange={e => setInputValue(Number(e))} value={inputValue} />
        </CardGrey>
        <ButtonsBox>
          <Button onClick={() => setIsMenuOpened(false)}>Cancel</Button>
          <ButtonChangeStyle
            onClick={async () => {
              await onAccept(inputValue);
              setIsMenuOpened(false);
            }}
          >
            Change
          </ButtonChangeStyle>
        </ButtonsBox>
      </DrawerStyle>
    </>
  );
};
