import React from 'react';
import styled from 'styled-components';
import { TopNav } from '../../components/TopNav/TopNav';
import { Card } from '../../components/Card/Card';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../global/AppRouter/routes';

const MainComponent = styled.div`
  padding: 160px 20px 0 20px;
`;

const GreyCard = styled(Card)`
  padding: 40px 24px;
  ${props => props.theme.typography.subtitle1};
  font-weight: bold;
`;

const LinkStyle = styled(Link)`
  color: ${props => props.theme.colors.utils.text.dark};
  text-decoration: none;
  font-weight: bold;
`;

export const DashBoardMenu = () => (
  <>
    <TopNav>Admin dashboard</TopNav>
    <MainComponent>
      <LinkStyle to={APP_ROUTES.LEAGUES_DATA}>
        <GreyCard>Leagues Data</GreyCard>
      </LinkStyle>
      <LinkStyle to={APP_ROUTES.AD_MANAGER}>
        <GreyCard>Ad manager</GreyCard>
      </LinkStyle>
    </MainComponent>
  </>
);
