import { Player, BettingPlayerStatus } from '../beTypes';
import { getUserName } from './token';

export const isPlayer = (league: any, status: BettingPlayerStatus, userName: string) => {
  const userPlayer = league.players.filter((player: Player) => player.userName === userName)[0];

  if (userPlayer && userPlayer.status === status) {
    return true;
  }

  return false;
};

export const isUserPlayerWaiting = (league: any) => {
  return isPlayer(league, 'waiting', getUserName());
};

export const isUserPlayerBanned = (league: any) => {
  return isPlayer(league, 'banned', getUserName());
};
