import React, { FC, useState } from 'react';
import { Button, Drawer, Input, message } from 'antd';
import { Card } from '../Card/Card';
import styled from 'styled-components';
import joinLeague from '../../assets/joinLeague.svg';
import { APP_ROUTES } from '../../global/AppRouter/routes';
import { useHistory } from 'react-router-dom';

const CardGrey = styled(Card)`
  text-align: left;
  padding: 24px 52px 24px 24px;
  ${props => props.theme.typography.body2};
  margin-bottom: 10px;
  border-left: none;
  border-right: none;
`;

const InputStyle = styled(Input)`
  margin: 24px 0 0 30px;
  width: auto;
`;

const ButtonChangeStyle = styled(Button)`
  background-color: ${props => props.theme.colors.main.primary};
  margin-left: 8px;
  color: ${props => props.theme.colors.utils.text.light};
  margin-right: 16px;
`;

const ButtonsBox = styled.div`
  display: flex;
  float: right;
`;

const ImgStyle = styled.img`
  margin-left: 4px;
`;

const DrawerStyle = styled(Drawer)`
  && {
    .ant-drawer-content-wrapper {
      height: auto !important;
    }
    .ant-drawer-body {
      padding: 0 0 10px 0px;
    }
    .ant-drawer-header {
      border: none;
    }
    .ant-drawer-title {
      font-weight: bold;
    }
  }
`;

const TextAndImgBox = styled.div`
  font-weight: bold;
`;

const GreyCard = styled(Card)`
  && {
    padding: 40px;
    display: flex;
    font-weight: bold;
    width: 100%;
  }
`;

type DrawerLeaguePropsType = {
  onAccept: (arg: string) => any;
  PIN?: string;
  isOpened: boolean;
};

//todo: it should be one single component with DrawerBottom
export const DrawerLeague: FC<DrawerLeaguePropsType> = ({ PIN = '', onAccept, isOpened }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(isOpened);
  const [inputValue, setInputValue] = useState<string>(PIN);
  const history = useHistory();
  return (
    <>
      <GreyCard onClick={() => setIsMenuOpened(true)}>
        <TextAndImgBox>
          Join league
          <ImgStyle src={joinLeague} alt="Join League" />
        </TextAndImgBox>
      </GreyCard>
      <DrawerStyle
        title="Join League"
        placement="bottom"
        closable={false}
        onClose={() => setIsMenuOpened(false)}
        visible={isMenuOpened}
      >
        <CardGrey>
          Insert league PIN number
          <InputStyle placeholder="PIN" value={inputValue} onChange={e => setInputValue(e.target.value)} />
        </CardGrey>
        <ButtonsBox>
          <Button
            onClick={() => {
              setIsMenuOpened(false);
            }}
          >
            Cancel
          </Button>
          <ButtonChangeStyle
            onClick={async () => {
              await onAccept(inputValue).then((data: any) => {
                if (data) {
                  history.push(APP_ROUTES.LEAGUE(data.updatedPrivateLeague.name, inputValue, 'GAME'));
                } else {
                  message.error(`Incorrect PIN or player already joined`);
                }
              });
              setIsMenuOpened(false);
            }}
          >
            Join
          </ButtonChangeStyle>
        </ButtonsBox>
      </DrawerStyle>
    </>
  );
};
