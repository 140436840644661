import React, { FC } from 'react';
import styled from 'styled-components';

export type CardType = {
  onClick?: () => void;
  className?: string;
};

const GreyBorder = styled.div`
  border: 1px solid ${props => props.theme.colors.utils.border.mid};
  border-radius: 2px;
  text-align: center;
  justify-content: center;
  display: flex;
  ${props => props.theme.typography.subtitle1};
  color: ${props => props.theme.colors.utils.text.dark};
  margin-bottom: 32px;
  flex-direction: column;
`;

export const Card: FC<CardType> = ({ children, className, onClick }) => {
  return (
    <GreyBorder className={className} onClick={onClick}>
      {children}
    </GreyBorder>
  );
};
