/* eslint-disable @typescript-eslint/camelcase */
const plTranslations = {
  pl: {
    translation: {
      HelloWorld: 'Hello world',
    },
  },
};

export default plTranslations;
