import React from 'react';
import { Badge, Table } from 'antd';
import styled from 'styled-components';
import Dollar from 'assets/Dollar.svg';
import { DrawerBottom } from '../../components/DrawerBottom/DrawerBottom';
import { Team } from '../../beTypes';
import { useParams } from 'react-router-dom';
import { queryCache, useMutation } from 'react-query';
import FirebaseService from 'global/Firebase/FirebaseService';
import { REAL_LEAGUE_TEAMS } from '../../helpers/RequestKeys';
import { TeamLogo } from '../../components/TeamLogo/TeamLogo';

const CreditStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.theme.typography.subtitle1};
  font-weight: bold;
`;

const ImgStyled = styled.img`
  margin-left: 8px;
`;

const StyledCreditCell = styled(Badge)`
  float: center;
  .ant-badge-count {
    background: ${props => props.theme.colors.main.secondary};
  }
`;

const CreditCellContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.div`
  ${props => props.theme.typography.subtitle1};
  font-weight: bold;
`;

type DashboardTableType = {
  realLeagueTeams: Team[];
};

type OverrideCreditsData = {
  credits: number;
  realLeagueId: number;
  teamId: number;
};

type CreditsData = {
  teamName: string;
} & OverrideCreditsData;

export const DashboardTable = ({ realLeagueTeams }: DashboardTableType) => {
  const { id: realLeagueId } = useParams();
  const [overrideLeagueTeamCredits] = useMutation(
    (overrideCreditsData: OverrideCreditsData) =>
      FirebaseService.overrideLeagueTeamCredits(
        overrideCreditsData.credits,
        overrideCreditsData.realLeagueId,
        overrideCreditsData.teamId
      ),
    {
      onSuccess: resp => {
        queryCache.invalidateQueries([REAL_LEAGUE_TEAMS, resp.team.leagueId]);
      },
    }
  );

  const columns = [
    {
      title: <StyledText>Team</StyledText>,
      dataIndex: 'team',
      width: '50%',
      editable: true,
      render: (name: string, record: any) => {
        return (
          <div>
            <TeamLogo logoUrl={record.logo} />
            {name}
          </div>
        );
      },
    },
    {
      title: (
        <CreditStyled>
          Credits
          <ImgStyled src={Dollar} alt="Dollar sign" />
        </CreditStyled>
      ),
      dataIndex: `creditsData`,
      width: '30%',
      editable: true,
      render: (creditsData: CreditsData) => {
        return (
          <CreditCellContainer>
            <StyledCreditCell count={creditsData.credits} showZero />
          </CreditCellContainer>
        );
      },
    },
    {
      title: <StyledText>Edit</StyledText>,
      dataIndex: 'creditsData',
      render: (creditsData: CreditsData) => (
        <DrawerBottom
          credits={creditsData.credits}
          name={creditsData.teamName}
          onAccept={inputValue => {
            if (!inputValue) {
              return null;
            }
            return overrideLeagueTeamCredits({ credits: inputValue, realLeagueId, teamId: creditsData.teamId });
          }}
        />
      ),
    },
  ];

  const data = realLeagueTeams.map(el => ({
    key: el.id,
    team: el.name,
    logo: el.logo,
    creditsData: {
      credits: el.credits,
      realLeagueId,
      teamId: el.id,
      teamName: el.name,
    },
  }));

  return <Table columns={columns} dataSource={data} pagination={false} />;
};
