import React from 'react';
import { TopNav } from 'components/TopNav/TopNav';
import styled from 'styled-components';
import { DashboardTable } from './DashboardTable';
import { useParams } from 'react-router-dom';
import FirebaseService from 'global/Firebase/FirebaseService';
import { useQuery } from 'react-query';
import { REAL_LEAGUE_TEAMS } from '../../helpers/RequestKeys';
import { CenteredSpinner } from '../../components/CenteredSpinner/CenteredSpinner';
import _ from 'lodash';

const EditText = styled.div`
  ${props => props.theme.typography.overline};
  margin: 18px 0 18px 16px;
`;

export const DashboardLeaguesName = () => {
  const { id, name } = useParams();
  const { data } = useQuery<any, any, any>([REAL_LEAGUE_TEAMS, Number(id)], () =>
    FirebaseService.getRealLeagueTeams(id)
  );

  const teams = data && _.sortBy(data.teams, ['name']);

  return (
    <>
      <TopNav isWithBackArrow={true}>{name}</TopNav>
      <EditText>EDIT LEAGUES SCORES</EditText>
      {teams ? <DashboardTable realLeagueTeams={teams} /> : <CenteredSpinner />}
    </>
  );
};
