import TopNavLogo from '../../assets/topnav-logo.png';
import { TopNav } from '../TopNav/TopNav';
import React, { FC } from 'react';

type TopNavWithLogoPropTypes = {
  isWithBackArrow?: boolean;
};

export const TopNavWithLogo: FC<TopNavWithLogoPropTypes> = ({ isWithBackArrow }) => {
  return (
    <TopNav isWithBackArrow={isWithBackArrow}>
      <img src={TopNavLogo} height={44} alt="Predict leagues wide logo" />
    </TopNav>
  );
};
