import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { CoreTheme } from 'global/Themes/CoreTheme';
import { RWDProvider } from 'global/RWD';
import { GlobalStyles } from 'global/GlobalStyles/GlobalStyles';
import { ErrorHandler } from 'global/ErrorHandler/ErrorHandler';
import { AppRouter } from 'global/AppRouter/AppRouter';
import { FirebaseProvider, Firebase } from 'global/Firebase';
import { CookieBar } from './pages/CookieBar/CookieBar';
import { ReactQuery } from './global/ReactQuery/ReactQuery';

const firebase = new Firebase();

firebase.onAuthStateChanged(async user => {
  const idToken = await user?.getIdToken();
  localStorage.setItem('idToken', idToken || '');
});

const App: FunctionComponent = () => (
  <ThemeProvider theme={CoreTheme}>
    <ReactQuery />
    <FirebaseProvider value={firebase}>
      <RWDProvider>
        <Router>
          <GlobalStyles />
          <ErrorHandler>
            <AppRouter />
          </ErrorHandler>
          <CookieBar />
        </Router>
      </RWDProvider>
    </FirebaseProvider>
  </ThemeProvider>
);

export default App;
