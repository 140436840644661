import React, { FC } from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { TopNav } from 'components/TopNav/TopNav';
import { TableMenu } from '../../components/LeagueName/TableMenu';
import { GameMenuPreview } from '../../components/LeagueName/GameMenuPreview';
import { useParams } from 'react-router-dom';

//todo: It should be removed or better composition should be implemented.

const { TabPane } = Tabs;

const CenteredCol = styled(Tabs)`
  .ant-tabs {
    width: 100%;
  }
  
  .ant-tabs-nav {
    height: 54px;
    width: 100%;
    z-index: 999;
    text-align: center;
  }

  && {
  .ant-tabs-nav-wrap {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
  
    .ant-tabs-nav-list {
      width: 100%;
      text-align: center;
    }
    && .ant-tabs-tab {
      margin: 0px;
      border: 0px;
      width: 100%;
      background-color: ${props => props.theme.colors.main.primary};
      color: ${props => props.theme.colors.utils.background.light};
      ${props => props.theme.typography.subtitle2};
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-tabs-ink-bar {
      visibility: visible;
      background-color: ${props => props.theme.colors.utils.background.light};
    }
    .ant-tabs-content-holder {
      width: 100%;
    }
    .ant-tabs-nav {
      margin: 0px;
    }
    .ant-tabs-nav-list {
      background-color: ${props => props.theme.colors.main.primary};
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color:${props => props.theme.colors.utils.text.light}
  }
`;

export const LeagueMenuPreview: FC = () => {
  const { name } = useParams();

  return (
    <>
      <TopNav isWithBackArrow>{name}</TopNav>
      <CenteredCol defaultActiveKey="1">
        <TabPane tab="TABLE" key="1">
          <TableMenu />
        </TabPane>
        <TabPane tab="GAME" key="2">
          <GameMenuPreview />
        </TabPane>
      </CenteredCol>
    </>
  );
};
